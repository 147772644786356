import { useState, useContext } from "react";
import omit from "lodash/omit";

import { ArtistContext } from "../../../context";
import { useMediaQuery } from "../../../hooks";
import { useProjectSuggestionSearch } from "../../../hooks/api";
import {
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownItemsContainer,
  StyledDropdownHeading,
  StyledDropdownIcon,
  SelectDropdown,
} from "./styles";
import SuggestionSearchBox from "./SuggestionSearchBox";
import { useEffect } from "react";

const updates = { "Recent Updates": "Active", "No Update": "No update" };

const OtherDropdown = ({ heading, items, value, onSelect }) => {
  const [headingText, setHeadingText] = useState(heading);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    setHeadingText(value || heading);
  }, [value]);

  return (
    <StyledDropdown
      onMouseEnter={() => setIsDropdownVisible(true)}
      onMouseLeave={() => setIsDropdownVisible(false)}
    >
      <StyledDropdownHeading>{headingText}</StyledDropdownHeading>
      <StyledDropdownIcon />
      <StyledDropdownItemsContainer isVisible={isDropdownVisible}>
        {items.map((item) => {
          const handleOnClickItem = () => {
            setIsDropdownVisible(false);
            onSelect(item);
            setHeadingText(item);
          };
          return (
            <StyledDropdownItem key={item} onClick={handleOnClickItem}>
              {item}
            </StyledDropdownItem>
          );
        })}
      </StyledDropdownItemsContainer>
    </StyledDropdown>
  );
};

const OtherFilters = ({ finalFilters, onChange }) => {
  const [filters, setFilters] = useState({});
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { filterData } = useContext(ArtistContext);
  const [searchQuery, setSearchQuery] = useState("");
  const projectSuggestions = useProjectSuggestionSearch(searchQuery);

  useEffect(() => {
    setFilters(finalFilters);
  }, [finalFilters]);

  const handleOnChangeFilter = (type) => (value) => {
    const updatedFilters = !value
      ? omit(filters, type)
      : { ...filters, [type]: value };
    setFilters(updatedFilters);
    onChange && onChange(updatedFilters);
  };

  if (isMobileWindow || filterData === undefined) {
    return null;
  }

  return (
    <>
      <OtherDropdown
        heading="Genre"
        items={filterData.genres}
        value={filters.genre}
        onSelect={handleOnChangeFilter("genre")}
      />
      <OtherDropdown
        heading="Country"
        items={filterData.countries}
        value={filters.Country}
        onSelect={handleOnChangeFilter("Country")}
      />
      <OtherDropdown
        heading="Updates"
        items={Object.keys(updates)}
        onSelect={(value) => handleOnChangeFilter("Status")(updates[value])}
        value={filters.Status}
      />
      <SuggestionSearchBox
        suggestions={projectSuggestions}
        onChange={(value) => setSearchQuery(value)}
        onSelect={handleOnChangeFilter("search")}
        value={filters.search}
      />
    </>
  );
};

const MobileScreenFilters = ({ finalFilters, onChange }) => {
  const [filters, setFilters] = useState({});
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { filterData } = useContext(ArtistContext);
  const [searchQuery, setSearchQuery] = useState("");
  const projectSuggestions = useProjectSuggestionSearch(searchQuery);

  useEffect(() => {
    setFilters(finalFilters);
  }, [finalFilters]);

  const handleOnChangeFilter = (type) => (value) => {
    const updatedFilters = !value
      ? omit(filters, type)
      : { ...filters, [type]: value };
    setFilters(updatedFilters);
    onChange && onChange(updatedFilters);
  };

  if (!isMobileWindow || filterData === undefined) {
    return null;
  }

  return (
    <div className="container-fluid mt-3 mb-3">
      <div className="row">
        <SuggestionSearchBox
          size="full"
          suggestions={projectSuggestions}
          onChange={(value) => setSearchQuery(value)}
          onSelect={handleOnChangeFilter("search")}
          value={filters.search}
        />
        <SelectDropdown
          value={filters.genre || ""}
          onChange={(e) => handleOnChangeFilter("genre")(e.target.value)}
        >
          <option key="Select Genre" value="">
            Select Genre
          </option>
          {filterData.genres.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </SelectDropdown>
        <SelectDropdown
          value={filters.Country || ""}
          onChange={(e) => handleOnChangeFilter("Country")(e.target.value)}
        >
          <option key="Select country" value="">
            Select country
          </option>
          {filterData.countries.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </SelectDropdown>
        <SelectDropdown
          value={filters.Status || ""}
          onChange={(e) => handleOnChangeFilter("Status")(e.target.value)}
        >
          <option key="Updates" value="">
            Select updates
          </option>
          {Object.keys(updates).map((key) => (
            <option key={key} value={updates[key]}>
              {key}
            </option>
          ))}
        </SelectDropdown>
      </div>
    </div>
  );
};

export { MobileScreenFilters, OtherFilters };

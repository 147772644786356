import styled from "styled-components";
import { photoDefault } from "../../assets/images";

// default
// accent_color: "white"
// accent_color_strong: "white"
// background_color: "white"
// background_image: null
// center_scale: true
// font_color: "white"
// id: 100
// image_attachment: "Fixed"
// image_key_s3: null
// image_position: "center"
// image_repeat: "No-repeat"
// overlay_color: "black"
// overlay_opacity: "40"
// user_id: 303

// accent_color: "hsl(144, 59%, 46%)"
// accent_color_strong: "hsl(0, 0%, 100%)"

// background_color: "hsl(63, 79%, 54%)"
// background_image: null
// center_scale: true
// font_color: "hsl(0, 0%, 100%)"
// id: 100
// image_attachment: "Fixed"
// image_key_s3: null
// image_position: "center"
// image_repeat: "No-repeat"
// overlay_color: "hsl(0, 0%, 0%)"
// overlay_opacity: "40"
// set_as_default: true
// user_id: 303

const getStyle = (settings) => {
  if (!settings) {
    return {};
  }
  return {
    // accent_color: white
    // accent_color_strong: "white"
    backgroundColor: settings.background_color || "hsl(0, 0%, 100%)",
    backgroundImage: settings.background_image
      ? `url(${settings.background_image})`
      : `url(${photoDefault})`,
    backgroundSize: settings.center_scale ? "cover" : "initial",
    color: settings.font_color || "white",
    imageAttachment: settings.image_attachment || "Fixed",
    imagePosition: settings.image_position || "center",
    backgroundRepeat: settings.image_repeat || "No-repeat",
  };
};

const getOverlayStyle = (settings) => {
  if (!settings) {
    return {};
  }
  return {
    backgroundColor: settings.overlay_color || "hsl(0, 0%, 100%)",
    opacity: parseInt(settings.overlay_opacity || "40") / 100,
  };
};

const PlaylistContainer = styled.div.attrs((props) => ({
  style: getStyle(props.settings),
}))`
  width: 100%;
  height: 100%;
  position: relative;
`;
const PlaylistContainerOverlay = styled.div.attrs((props) => ({
  style: getOverlayStyle(props.settings),
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const PlaylistIconContainer = styled.a`
  padding: 25px;
  text-decoration: none;
  font-size: 20px;
  color: ${({ settings }) => (settings && settings.accent_color) || "#fff"};

  &:hover {
    color: ${({ settings }) =>
      (settings && settings.accent_color_strong) || "#fff"};
    cursor: pointer;
  }
`;
const EditIconContainer = styled(PlaylistIconContainer)`
  position: absolute;
  top: 15px;
  left: 10px;
`;
const StyledAddSong = styled(PlaylistIconContainer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid
    ${({ settings }) => (settings && settings.accent_color) || "#fff"};
  text-align: center;
  font-size: 30px;
  padding: 0px;

  input {
    position: absolute;
    opacity: 0;
    background-color: "green";
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    border-color: ${({ settings }) =>
      (settings && settings.accent_color_strong) || "#fff"};
  }
`;
const PlaylistOptionsContainer = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export {
  PlaylistContainer,
  PlaylistContainerOverlay,
  PlaylistIconContainer,
  EditIconContainer,
  StyledAddSong,
  PlaylistOptionsContainer,
};

import { useState } from "react";
import styled from "styled-components";

import { Modal, ModalStyles, Switch } from "../../../commonUI";
import { Input } from "../../../styled";

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: #d9534f;
  color: #fff;
  font-size: 12px;
  border: 0;
`;

const fields = [
  { title: "Song title", name: "title" },
  { title: "Artist", name: "artist" },
  { title: "Album", name: "album" },
  { title: "genre", name: "genre" },
  { title: "Keywords", name: "keywords" },
  { title: "ISRC code", name: "isrc_code" },
  { title: "ISWC code", name: "iswc_code" },
  { title: "Representation", name: "representation_info" },
];

const SongsInfoModal = ({
  isVisible,
  info,
  onCloseModal,
  onUpdate,
  onDelete,
  isRegisteredUser,
}) => {
  const [data, setData] = useState(info);
  const [isConsentModalVisible, setIsConsentModalVisible] = useState(false);

  const onCloseConsentModal = () => setIsConsentModalVisible(false);

  const renderDetailsForRegisteredUser = () => {
    return fields.map(({ title, name }) => (
      <Modal.FormGroup key={title} type="click_to_edit" title={title}>
        {isRegisteredUser ? (
          <span style={{ ...(!data[name] && { color: "#777" }) }}>
            {data[name] || "Click to edit"}
          </span>
        ) : null}
        <Input
          ref={(ref) => ref?.focus()}
          value={data[name] || ""}
          disabled={!!isRegisteredUser}
          onChange={(e) => setData({ ...data, [name]: e.target.value })}
          onBlur={() => onUpdate({ [name]: data[name] })}
          type="text"
        />
      </Modal.FormGroup>
    ));
  };
  const renderDetailsForOthers = () => {
    return fields.map(({ title, name }) => (
      <Modal.FormGroup title={title}>{data[name] || ""}</Modal.FormGroup>
    ));
  };

  return (
    <>
      <Modal isVisible={isVisible}>
        <Modal.Header
          icon="fa fa-info-circle"
          title="Song info"
          onCloseModal={onCloseModal}
        />
        <Modal.Form>
          {isRegisteredUser
            ? renderDetailsForRegisteredUser()
            : renderDetailsForOthers()}
          <Modal.FormGroup title="Size">{`${data.size} MB`}</Modal.FormGroup>
          {isRegisteredUser ? (
            <>
              <Modal.FormGroup title="Is downloadable">
                <Switch
                  value={(data && data.can_download) || false}
                  onChange={(isChecked) =>
                    onUpdate({ can_download: isChecked })
                  }
                />
              </Modal.FormGroup>
              <Modal.FormGroup title="Delete">
                <DeleteButton onClick={() => setIsConsentModalVisible(true)}>
                  <i className="fa fa-trash-o" />
                </DeleteButton>
              </Modal.FormGroup>
            </>
          ) : null}
        </Modal.Form>
      </Modal>
      {isRegisteredUser ? (
        <Modal isVisible={isConsentModalVisible}>
          <Modal.Header
            title="Delete song"
            onCloseModal={onCloseConsentModal}
          />
          <ModalStyles.Body>Are you sure?</ModalStyles.Body>
          <ModalStyles.SubmitContainer>
            <ModalStyles.SubmitButton kind="success" onClick={onDelete}>
              Yes
            </ModalStyles.SubmitButton>
            <ModalStyles.SubmitButton
              kind="default"
              onClick={onCloseConsentModal}
            >
              No
            </ModalStyles.SubmitButton>
          </ModalStyles.SubmitContainer>
        </Modal>
      ) : null}
    </>
  );
};

export default SongsInfoModal;

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

export const formatDate = (date, pattern) => format(parseISO(date), pattern);

export const isValidEmailAddress = (text) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text);
};

export const isValidPassword = (text) => {
  const char = /[a-zA-Z]+/;
  const num = /[0-9]+/;

  return char.test(text) && num.test(text) && text.length >= 8;
};

export const isValidURL = (str) => {
  // The URL must start with either http or https and
  // then followed by :// and
  // then it must contain www. and
  // then followed by subdomain of length (2, 256) and
  // last part contains top level domain like .com, .org etc.
  const regx = new RegExp(
    `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`
  );

  return regx.test(str);
};

import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

import reducers from "../reducers";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["app", "users"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const config = () => {
  const store = createStore(persistedReducer);
  const persistor = persistStore(store);

  return { store, persistor };
};

export default config;

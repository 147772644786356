import { API_BASE } from "../config";

export const ERROR_MESSAGE = "Something went wrong. Please try again later.";
export const TOKEN_EXPIRED_MESSAGE = "token_expired";
const NOT_PERMITTED_ERROR_CODE = 403;

export const formatHTTPResponse = (httpResponse) => {
  if (
    httpResponse.ok &&
    httpResponse.status >= 200 &&
    httpResponse.status < 300
  ) {
    return httpResponse.json();
  } else {
    return { isSuccess: false, message: ERROR_MESSAGE };
  }
};
export const handlePermittedResponse = (response) => {
  if (response.error_code === NOT_PERMITTED_ERROR_CODE) {
    return { isSuccess: false, message: TOKEN_EXPIRED_MESSAGE };
  }
  return response;
};
const formatErrorResponse = (error) => {
  return { isSuccess: false, message: error.message || ERROR_MESSAGE };
};

const GET = (subPath) => async (payload) => {
  return fetch(APIPath(subPath, payload.params), {
    method: "GET",
    headers: header(payload),
    credentials: "include",
  })
    .then((response) => formatHTTPResponse(response))
    .then((response) => handlePermittedResponse(response))
    .catch((error) => formatErrorResponse(error));
};

const POST = (subpath) => async (payload) => {
  return fetch(APIPath(subpath, payload.params), {
    method: "POST",
    headers: header(payload),
    body: JSON.stringify(payload.body),
    credentials: "include",
  })
    .then((response) => formatHTTPResponse(response))
    .then((response) => handlePermittedResponse(response))
    .catch((error) => formatErrorResponse(error));
};

const PUT = (subpath) => async (payload) => {
  return fetch(APIPath(subpath, payload.params), {
    method: "PUT",
    headers: header(payload),
    body: JSON.stringify(payload.body),
    credentials: "include",
  })
    .then((response) => formatHTTPResponse(response))
    .then((response) => handlePermittedResponse(response))
    .catch((error) => formatErrorResponse(error));
};

const DELETE = (subpath) => async (payload) => {
  return fetch(APIPath(subpath, payload.params), {
    method: "DELETE",
    headers: header(payload),
    body: JSON.stringify(payload.body),
    credentials: "include",
  })
    .then((response) => formatHTTPResponse(response))
    .then((response) => handlePermittedResponse(response))
    .catch((error) => formatErrorResponse(error));
};

const APIPath = (endPoint, params) => {
  let querystring = "";
  if (params) {
    querystring =
      querystring +
      Object.keys(params)
        .map((key) => key + "=" + encodeURIComponent(params[key]))
        .join("&");
  }
  let path = API_BASE + "/" + endPoint;

  return querystring === "" ? path : path + "?" + querystring;
};

const FILE_UPLOAD = (subPath) => async (payload) => {
  const formdata = new FormData();
  Object.keys(payload.body).forEach((key) => {
    if (
      Object.prototype.toString.call(payload.body[key]) === "[object Array]"
    ) {
      payload.body[key].map((v, index) =>
        Object.keys(v).map((item) =>
          formdata.append(
            `${String(key)}[${index}][${item}]`,
            `${String(v[item])}`
          )
        )
      );
    } else {
      formdata.append(String(key), payload.body[key]);
    }
  });

  return fetch(APIPath(subPath, payload.params), {
    method: "POST",
    body: formdata,
    credentials: "include",
  })
    .then((response) => response.text())
    .then((result) => result)
    .catch((error) => formatErrorResponse(error));
};

const header = (payload) => {
  return {
    // "Content-type": "application/json",
    "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    ...(payload.token && { Authorization: payload.token }),
    ...payload.headers,
  };
};

export { GET, POST, PUT, DELETE, FILE_UPLOAD };

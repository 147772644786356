import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import config from "./redux/store";
import ErrorBoundary from "./ErrorBoundary";
import Routes from "./routes";

const { store, persistor } = config();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default App;

import { useState, useEffect } from "react";

import { getVideoAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useVideos = () => {
  const [videos, setVideos] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  const fetchVideos = (params) => {
    fetch({
      api: getVideoAPI,
      payload: { params: { ...params, limit: 100 } },
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      if (response) {
        setVideos(response);
      } else {
        setVideos([]);
      }
    }
  }, [isLoading]);

  return { videos, fetchVideos };
};

export default useVideos;

import { useState, useEffect } from "react";

import { getSearchAPI } from "../../api/project.http.service";
import useFetchAPI from "../useFetchAPI";
import useDebounce from "../useDebounce";

const contactFields = ["Contact1", "Contact2", "Contact3", "Contact4"];

const hasTextInString = (value, text) => {
  const regEx = new RegExp(`${text}`, "gi");
  return regEx.test(value);
};

const useProjectSuggestionSearch = (search) => {
  const [suggestions, setSuggestions] = useState([]);
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const delayedSearch = useDebounce(search, 400);

  useEffect(() => {
    delayedSearch &&
      fetch({
        api: getSearchAPI,
        payload: {
          params: {
            populate:
              "primaryVideo_id,videos,contact1_id,contact2_id,contact3_id,contact4_id",
            sort: "Artist",
            search: delayedSearch,
          },
        },
      });
  }, [delayedSearch]);

  useEffect(() => {
    if (
      isLoading === false &&
      response &&
      response.count &&
      response.projects
    ) {
      const projects = new Set();
      const contacts = new Set();

      response.projects.forEach((project) => {
        project.Artist &&
          hasTextInString(project.Artist, delayedSearch) &&
          projects.add(project.Artist);
        contactFields.forEach((field) => {
          project[field] &&
            hasTextInString(project[field], delayedSearch) &&
            contacts.add(project[field]);
        });
      });

      const arr = [];
      projects.size &&
        arr.push({
          title: "PROJECT TITLE",
          data: [...projects].sort(),
        });
      contacts.size &&
        arr.push({
          title: "CONTACT",
          data: [...contacts].sort(),
        });

      setSuggestions(arr);
    }
  }, [isLoading]);

  return suggestions;
};

export default useProjectSuggestionSearch;

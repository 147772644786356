import { Switch, Route, Redirect } from "react-router-dom";

import { StyledAdminHeader } from "./styles";
import Users from "./Users";
import Audits from "./Audits";
import Albums from "./Albums";
import Videos from "./Videos";
import Projects from "./Projects";
import { ROUTE } from "../../helper/constants";

const BACKEND_ROUTE = ROUTE.backend;

const headerItems = [
  { label: "Users", route: "users" },
  { label: "Audits", route: "audits" },
  { label: "Albums", route: "albums" },
  { label: "Videos", route: "videos" },
  { label: "Projects", route: "projects" },
];
const Backend = () => {
  return (
    <div style={{ backgroundColor: "#f0f3f4" }}>
      <StyledAdminHeader>
        {headerItems.map(({ label, route }) => (
          <a href={`/${BACKEND_ROUTE.landing}/${route}`}>{label}</a>
        ))}
      </StyledAdminHeader>
      <Switch>
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}`}
          render={() => (
            <Redirect to={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.users}`} />
          )}
        />
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.users}`}
          component={Users}
        />
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.audits}`}
          component={Audits}
        />
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.albums}`}
          component={Albums}
        />
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.videos}`}
          component={Videos}
        />
        <Route
          exact
          path={`/${BACKEND_ROUTE.landing}/${BACKEND_ROUTE.projects}`}
          component={Projects}
        />
        <Redirect to={`/${BACKEND_ROUTE.landing}`} />
      </Switch>
    </div>
  );
};

export default Backend;

import { useState, useEffect } from "react";

import { usePlaylistSetting, useUserPlaylist } from "../../hooks/api";
import PlaylistContext from "./PlaylistContext";
import { PlaylistContainer, PlaylistContainerOverlay } from "./styles";
import EditUser from "./EditUser";
import Options from "./Options";
import Songs from "./Songs";

const Playlist = () => {
  const playlistSetting = usePlaylistSetting();
  const { userPlaylist, fetchPlaylist } = useUserPlaylist(false);
  const [settings, setSettings] = useState(playlistSetting);
  const [selectedPlaylist, setSelectedPlaylist] = useState(undefined);
  const [isSongDragable, setIsSongDragable] = useState(true);
  const [isFirstPlaylistFetched, setIsFirstPlaylistFetched] =
    useState(undefined);

  const handleOnChangePlaylist = (index, data) => {
    index !== null
      ? setSelectedPlaylist({ index, data })
      : setSelectedPlaylist(undefined);
  };

  useEffect(() => {
    fetchPlaylist();
    setIsFirstPlaylistFetched(false);
  }, []);

  useEffect(() => {
    if (isFirstPlaylistFetched === false) {
      if (userPlaylist && userPlaylist.length) {
        handleOnChangePlaylist(0, userPlaylist[0]);
        setIsFirstPlaylistFetched(true);
      } else {
        handleOnChangePlaylist(null);
      }
    }
  }, [userPlaylist]);

  useEffect(() => {
    setSettings(playlistSetting);
  }, [playlistSetting]);

  return (
    <PlaylistContext.Provider
      value={{
        settings,
        updateSettings: setSettings,
        userPlaylist,
        fetchPlaylist,
        selectedPlaylist,
        onChangePlaylist: handleOnChangePlaylist,
        isSongDragable,
        onAlterSongDragable: (val) =>
          setIsSongDragable(val !== undefined ? val : !isSongDragable),
      }}
    >
      <PlaylistContainer settings={settings}>
        <PlaylistContainerOverlay settings={settings} />
        <EditUser />
        <Songs settings={settings} isRegisteredUser />
        <Options />
      </PlaylistContainer>
    </PlaylistContext.Provider>
  );
};

export default Playlist;

import { useState, useContext } from "react";

import PlaylistContext from "../../PlaylistContext";
import { EditPlayListButton } from "./styles";
import PlaylistForm from "./PlaylistForm";

const EditPlaylist = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { selectedPlaylist } = useContext(PlaylistContext);

  return (
    <>
      <EditPlayListButton onClick={() => setIsVisible(true)}>
        [Edit playlist title]
      </EditPlayListButton>
      <PlaylistForm
        type="edit"
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        prevTitle={(selectedPlaylist && selectedPlaylist.data.name) || ""}
        id={(selectedPlaylist && selectedPlaylist.data.id) || ""}
      />
    </>
  );
};

export default EditPlaylist;

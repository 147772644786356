import { useState, useEffect, useContext } from "react";

import { Modal, ModalStyles } from "../../../../commonUI";
import { useDeletePlaylist } from "../../../../hooks/api";
import PlaylistContext from "../../PlaylistContext";
import { Icon } from "./styles";

const RemovePlaylist = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isSuccessful, deletePlaylist } = useDeletePlaylist();
  const { selectedPlaylist } = useContext(PlaylistContext);

  const onClose = () => setIsVisible(false);

  useEffect(() => {
    if (isSuccessful) {
      onClose();
    }
  }, [isSuccessful]);

  const onClickDelete = () => deletePlaylist(selectedPlaylist.data.id);

  return (
    <>
      <Icon bg="red" onClick={() => setIsVisible(true)}>
        <i className="fa fa-trash" />
      </Icon>
      <Modal isVisible={isVisible}>
        <Modal.Header title="Remove playlist" onCloseModal={onClose} />
        <ModalStyles.Body>
          Are you sure? (This can not be undone)
        </ModalStyles.Body>
        <ModalStyles.SubmitContainer>
          <ModalStyles.SubmitButton kind="success" onClick={onClickDelete}>
            Yes
          </ModalStyles.SubmitButton>
          <ModalStyles.SubmitButton kind="default" onClick={onClose}>
            No
          </ModalStyles.SubmitButton>
        </ModalStyles.SubmitContainer>
      </Modal>
    </>
  );
};

export default RemovePlaylist;

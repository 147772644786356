import { useState, useEffect } from "react";

import { getSearchAPI } from "../../api/project.http.service";
import useFetchAPI from "../useFetchAPI";
// import useIsMounted from "./useIsMounted";

const limit = 30;

const useProjectSearch = (params, pageNumber) => {
  // const isMounted = useIsMounted();
  const [projects, setProjects] = useState(undefined);
  const [count, setCount] = useState(undefined);
  const [hasMore, setHasMore] = useState(false);
  const [{ isLoading, error, response }, fetch] = useFetchAPI();

  useEffect(() => {
    setProjects(undefined);
  }, [params]);

  useEffect(() => {
    // isMounted &&
    fetch({
      api: getSearchAPI,
      payload: {
        params: {
          populate:
            "primaryVideo_id,videos,contact1_id,contact2_id,contact3_id,contact4_id",
          limit,
          skip: pageNumber * limit,
          sort: "Artist",
          ...params,
        },
      },
    });
  }, [params, pageNumber]);

  useEffect(() => {
    if (
      isLoading === false &&
      response &&
      response.count &&
      response.projects
    ) {
      setProjects((prevProjects) => [
        ...(prevProjects || []),
        ...response.projects,
      ]);
      setCount(response.count);
      setHasMore(response.count > (pageNumber + 1) * limit);
    }
  }, [isLoading]);

  return { isLoading, error, count, projects, hasMore };
};

export default useProjectSearch;

import { useEffect } from "react";

import { Table } from "../../../commonUI";
import { useProjects } from "../../../hooks/api";

const columns = [
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
  },
  {
    title: "Artist",
    dataIndex: "Artist",
    key: "Artist",
  },
  {
    title: "Website",
    dataIndex: "Website",
    key: "Website",
  },
  {
    title: "Project Notes",
    dataIndex: "ProjectNotes",
    key: "ProjectNotes",
  },
  {
    title: "Genre1",
    dataIndex: "Genre1",
    key: "Genre1",
  },
  {
    title: "Genre2",
    dataIndex: "Genre2",
    key: "Genre2",
  },
  {
    title: "Contact1",
    dataIndex: "Contact1",
    key: "Contact1",
  },
  {
    title: "Contact2",
    dataIndex: "Contact2",
    key: "Contact2",
  },
  {
    title: "Contact3",
    dataIndex: "Contact3",
    key: "Contact3",
  },
  {
    title: "Contact4",
    dataIndex: "Contact4",
    key: "Contact4",
  },
];

const Videos = () => {
  const { projects, fetchProjects } = useProjects();

  useEffect(() => {
    fetchProjects({});
  }, []);

  if (!projects) {
    return null;
  }

  const projectsTableData = [];

  projects?.map((audit) => {
    const {
      id,
      Status,
      title,
      Website,
      ProjectNotes,
      Genre1,
      Genre2,
      Contact1,
      Contact2,
      Contact3,
      Contact4,
    } = audit;

    return projectsTableData.push({
      key: id,
      Status,
      title,
      Website,
      ProjectNotes,
      Genre1: Genre1 || "",
      Genre2: Genre2 || "",
      Contact1: Contact1 || "",
      Contact2: Contact2 || "",
      Contact3: Contact3 || "",
      Contact4: Contact4 || "",
    });
  });

  return (
    <>
      <Table
        dataSource={projectsTableData}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default Videos;

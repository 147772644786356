import styled from "styled-components";

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #ccc;
  border-radius: 34px;

  -webkit-transition: 0.4s ease-out all;
  transition: 0.4s ease-out all;

  :before {
    content: "";
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    border-radius: 50%;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${StyledSpan} {
    background-color: #5cb85c;
    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
  &:focus + ${StyledSpan} {
    box-shadow: 0 0 1px #5cb85c;
  }
`;

const Switch = ({ value, onChange }) => {
  const handleOnChange = (e) => onChange && onChange(e.target.checked);

  return (
    <StyledLabel>
      <StyledInput type="checkbox" checked={value} onChange={handleOnChange} />
      <StyledSpan />
    </StyledLabel>
  );
};

export default Switch;

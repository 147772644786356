import styled from "styled-components";

const Reset = styled.span`
  color: #337ab7;
  cursor: pointer;
`;

const ArtistCount = ({ count, onReset }) => {
  return (
    <div className="col-6  p-0">
      {count || "..."} results found (<Reset onClick={onReset}>reset</Reset>)
    </div>
  );
};

export default ArtistCount;

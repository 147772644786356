import styled from "styled-components";

const StyledButton = styled.button.attrs({
  type: "button",
})`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;

  ${({ transparent }) =>
    transparent
      ? `
      color: #5cb85c;
      border: 0;
      background-color: transparent;
      padding: 0;
    `
      : `
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 6px 12px;
      width: 100%;
  `}
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;

import styled from "styled-components";

const Picker = styled.label`
  position: relative;
  width: 36px;
  height: 36px;
  border: 1px solid #5cb85c;
  border-radius: 4px;
  background-color: #5cb85c;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
const Icon = styled.i.attrs({
  className: "fa fa-picture-o",
})`
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  font-size: 1.5rem
  z-index: 1;
`;

const ImagePicker = (props) => {
  return (
    <Picker>
      <input
        type="file"
        accept="image/jpg"
        style={{ width: "100%", height: "100%", visibility: "hidden" }}
        {...props}
      />
      <Icon />
    </Picker>
  );
};

export default ImagePicker;

import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid;
  border-color: ${({ isInvalid }) => (isInvalid ? "#f05050" : "#ccc")};
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s;
  -webkit-box-shadow: ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus,
  &:hover {
    border-color: ${({ isInvalid }) => (isInvalid ? "#f05050" : "#66afe9")};
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px
        ${({ isInvalid }) =>
          isInvalid ? "rgb(240 80 80 / 60%)" : "rgb(102 175 233 / 60%)"};
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px
        ${({ isInvalid }) =>
          isInvalid ? "rgb(240 80 80 / 60%)" : "  rgb(102 175 233 / 60%)"};
  }
`;

export default Input;

import { useState, useEffect, useContext, useRef } from "react";
import pick from "lodash/pick";

import { Modal } from "../../../commonUI";
import { Input } from "../../../styled";
import { useUserInfo } from "../../../hooks/api";
import { putProfileUpdateAPI } from "../../../api/user.http.service";
import PlaylistContext from "../PlaylistContext";
import { EditIconContainer } from "../styles";

const fields = [
  { title: "Company", name: "company_name" },
  { title: "Key contact", name: "name" },
  { title: "Email", name: "email" },
  { title: "Phone", name: "phone" },
  { title: "Website", name: "website" },
];

const updateProfile = (body) => {
  putProfileUpdateAPI({
    body,
    headers: {
      "Content-type": "application/json",
    },
  });
};

const Form = ({ userInfo, onUpdate }) => {
  const [data, setData] = useState(userInfo);

  useEffect(() => {
    onUpdate(userInfo);
    setData(userInfo);
  }, [userInfo]);

  return (
    <Modal.Form>
      {fields.map(({ title, name }) => (
        <Modal.FormGroup key={title} type="click_to_edit" title={title}>
          <span style={{ ...(!data[name] && { color: "#777" }) }}>
            {data[name] || "Click to edit"}
          </span>
          <Input
            ref={(ref) => ref?.focus()}
            value={data[name] || ""}
            onChange={(e) => setData({ ...data, [name]: e.target.value })}
            onBlur={() => updateProfile({ [name]: data[name] })}
            type="text"
          />
        </Modal.FormGroup>
      ))}
    </Modal.Form>
  );
};

const EditUser = () => {
  const { settings } = useContext(PlaylistContext);
  const [isVisible, setIsVisible] = useState(false);
  const userInfo = useUserInfo(isVisible);
  const updatedUserInfo = useRef();

  useEffect(() => {
    return () => {
      updatedUserInfo.current &&
        updateProfile({
          ...pick(
            updatedUserInfo.current,
            fields
              .map(({ name }) => name)
              .filter(({ name }) => updatedUserInfo.current[name])
          ),
        });
    };
  }, []);

  const handleOnUpdate = (info) => {
    updatedUserInfo.current = info;
  };

  return (
    <>
      <EditIconContainer
        onClick={() => setIsVisible(true)}
        settings={settings}
        className="fa fa-envelope"
      />
      <Modal isVisible={isVisible}>
        <Modal.Header
          icon="fa fa-envelope"
          title="Contact"
          onCloseModal={() => setIsVisible(false)}
        />
        {userInfo ? (
          <Form userInfo={userInfo} onUpdate={handleOnUpdate} />
        ) : null}
      </Modal>
    </>
  );
};

export default EditUser;

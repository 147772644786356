import { useState, useEffect, useRef } from "react";

import { getUserAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useUsers = () => {
  const [users, setUsers] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [plans, setPlans] = useState([]);
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const page = useRef();

  const fetchUsers = (params) => {
    page.current = params.page;

    fetch({
      api: getUserAPI,
      payload: { params },
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      if (response?.status === "ok" && response?.users) {
        if (page.current === 1) {
          setTotal(response.data?.objCount || 0);
          setPageCount(response.data?.pageCount || 0);
          setPlans(response.plans || []);
        }
        setUsers(response.users);
      } else {
        setUsers([]);
      }
    }
  }, [isLoading]);

  return { users, fetchUsers, total, pageCount, plans };
};

export default useUsers;

import { useEffect, useContext } from "react";

import { useMediaQuery } from "../../../hooks";
import { useCollections } from "../../../hooks/api";
import { ArtistContext } from "../../../context";
import DetailOtherDevice from "./DetailOtherDevice";
import DetailMobileDevice from "./DetailMobileDevice";
import { DetailContainer, Curtain, First, Second } from "./styles";

const DetailFetcher = ({ children }) => {
  const { selectedArtist, updateSelectedArtist } = useContext(ArtistContext);
  const { collections } = useCollections(
    selectedArtist && selectedArtist.artist_id
  );

  useEffect(() => {
    updateSelectedArtist({
      ...selectedArtist,
      collections,
    });
  }, [collections]);

  return children;
};

const Detail = ({ isVisible }) => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { selectedArtist, setIsShowDetail } = useContext(ArtistContext);
  if (!isVisible) {
    return null;
  }

  const handleOnClickGoBack = () => setIsShowDetail(false);

  return (
    <DetailContainer>
      <DetailFetcher>
        {isMobileWindow ? (
          <DetailMobileDevice
            selectedArtist={selectedArtist}
            onClickBack={handleOnClickGoBack}
          />
        ) : (
          <DetailOtherDevice
            selectedArtist={selectedArtist}
            onClickBack={handleOnClickGoBack}
          />
        )}
      </DetailFetcher>
      <Curtain>
        <First />
        <Second />
      </Curtain>
    </DetailContainer>
  );
};

export default Detail;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { postLoginAPI } from "../../api/auth.http.service";
import { useFetchAPI } from "../../hooks";
import { mho72, mho72_2x } from "../../assets/images";
import { Switch, Button, Link, Logo } from "../../commonUI";
import { authAction } from "../../redux/actions";
import { ROUTE } from "../../helper/constants";
import {
  StyledLoginContainer,
  StyledLoginFormContainer,
  StyledLogoContainer,
  StyledFormItemContainer,
  StyledLabel,
  StyledInput,
  // StyledErrorLogo,
} from "./styles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [{ response }, fetchLogin] = useFetchAPI();

  const status = response && response.status;

  useEffect(() => {
    if (status === "ok") {
      dispatch({ type: authAction.LOGIN_SUCCESS, payload: { token: "xyz" } });
    }
  }, [status]);

  const handleOnLogin = () => {
    fetchLogin({
      api: postLoginAPI,
      payload: {
        body: {
          email,
          password,
        },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  return (
    <StyledLoginContainer>
      <StyledLoginFormContainer>
        <StyledLogoContainer>
          <Logo src={mho72} srcSet={mho72_2x} />
        </StyledLogoContainer>
        <StyledFormItemContainer>
          <StyledLabel>Email</StyledLabel>
          <StyledInput type="text" onChange={(e) => setEmail(e.target.value)} />
        </StyledFormItemContainer>
        <StyledFormItemContainer>
          <StyledLabel>Password</StyledLabel>
          <StyledInput
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </StyledFormItemContainer>
        <StyledFormItemContainer>
          <StyledLabel>Keep me signed in</StyledLabel>
          <Switch />
        </StyledFormItemContainer>
        <Button type="button" onClick={handleOnLogin}>
          Sign in
        </Button>
        <div className="d-flex justify-content-center">
          <Link href={`/${ROUTE.userForgot}`}>Forgot password?</Link>
        </div>
      </StyledLoginFormContainer>
    </StyledLoginContainer>
  );
};

export default Login;

import styled from "styled-components";

const StyledMainContainer = styled.div`
  position: absolute;
  top: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 -1px 10px rgb(0 0 0 / 10%);

  ${({ isNavOpen }) =>
    isNavOpen
      ? `
      transform: translate3d(0, 250px, 0) scale(0.9, 0.8);
      @media (min-width: 576px) {
        transform: translate3d(0, 20rem, 0) scale(0.85, 0.8);
      }
      transition: transform 0.5s;`
      : `
      transform: translate3d(0, 0, 0) scale(1, 1);
      transition: transform 0.5s;
    `}
`;

export { StyledMainContainer };

import { useState, useEffect } from "react";

import { getUserOptionAPI } from "../../api/user.http.service";
import useFetchAPI from "../useFetchAPI";

const usePlaylistSetting = () => {
  const [settings, setSettings] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  useEffect(() => {
    fetch({
      api: getUserOptionAPI,
      payload: {
        params: {},
      },
    });
  }, []);

  useEffect(() => {
    if (isLoading === false && response) {
      setSettings(response && response[0]);
    }
  }, [isLoading]);

  return settings;
};

export default usePlaylistSetting;

import React from "react";
import { Table } from "antd";
// import FeatherIcon from 'feather-icons-react';

import { TableWrapper, UserTableStyleWrapper } from "./styles";
// import Heading from "../../../components/heading/heading";
// import { Button } from '../../../components/buttons/buttons';
import { Cards } from "../Cards/frame/cards-frame";

const UserListTable = (props) => {
  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table {...props} />
        </TableWrapper>
      </UserTableStyleWrapper>
    </Cards>
  );
};

export default UserListTable;

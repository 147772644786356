import styled, { keyframes } from "styled-components";

const changeBackground = keyframes`
  from {
    background-color: transparent;
  }
  to {
    background-color: #fff;
  }
`;
const DetailContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  animation: ${changeBackground} 0.2s linear 1s forwards;
`;
const moveDownCurtain = keyframes`
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(100%);
  }
`;
const Curtain = styled.div`
  width: 100%;
  height: 200%;
  background-color: #f1b821;
  animation: ${moveDownCurtain} 6s ease both;
`;
const First = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
`;
const Second = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f1b821;
`;

const GoBackIcon = styled.div`
  height: 54px;
  width: 54px;
  color: #fff;
  font-size: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #f1b821;

  &:hover {
    background-color: #000;
    cursor: pointer;
  }
  &::before,
  i {
    vertical-align: middle;
  }
  &::before {
    content: "";
    width: 0;
    height: 100%;
  }

  @media (max-width: 767.98px) {
    margin-left: 20px;
  }
`;

export { DetailContainer, Curtain, First, Second, GoBackIcon };

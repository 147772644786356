import styled from "styled-components";

const StyledLoginContainer = styled.div.attrs({
  className: "row",
})`
  position: relative;
  padding: 1rem;
`;

const StyledLoginFormContainer = styled.form`
  position: absolute;
  left: 50%;
  margin-left: -160px;
  margin-top: 10%;
  width: 320px;
  color: #737373;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
`;
const StyledLogoContainer = styled.div.attrs({
  className: "text-center",
})``;

const StyledFormItemContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const StyledLabel = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 0.9rem;
  font-weight: 700;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  :hover {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }

  ${({ hasError }) =>
    hasError &&
    `
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  `}
`;

const StyledErrorLogo = styled.span.attrs({
  className: "fa fa-exclamation-circle",
})`
  position: absolute;
  top: 30px;
  right: 0;
  color: #a94442;
  line-height: 32px;
  width: 34px;
  height: 34px;
  text-align: center;
`;

export {
  StyledLoginContainer,
  StyledLoginFormContainer,
  StyledLogoContainer,
  StyledFormItemContainer,
  StyledLabel,
  StyledInput,
  StyledErrorLogo,
};

import { useState, useEffect } from "react";

import { getArtistDetailAPI } from "../../api/project.http.service";
import useFetchAPI from "../useFetchAPI";

const useCollections = (artistId) => {
  const [collections, setCollections] = useState([]);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  useEffect(() => {
    if (artistId) {
      fetch({
        api: getArtistDetailAPI,
        payload: {
          params: { artist_id: artistId },
        },
      });
    }
  }, [artistId]);

  useEffect(() => {
    if (isLoading === false && response) {
      setCollections(
        (response && response.filter(({ ignored }) => !ignored)) || []
      );
    }
  }, [isLoading]);

  return { collections };
};

export default useCollections;

import { useState, useEffect } from "react";

import { getAlbumAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useAlbums = () => {
  const [albums, setAlbums] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  const fetchAlbums = (params) => {
    fetch({
      api: getAlbumAPI,
      payload: { params },
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      if (response) {
        setAlbums(response);
      } else {
        setAlbums([]);
      }
    }
  }, [isLoading]);

  return { albums, fetchAlbums };
};

export default useAlbums;

import { useState, useEffect } from "react";
import omit from "lodash/omit";

import { Table, TableButton, PageHeader, Switch } from "../../../commonUI";
import { CardToolbox, Select } from "../../../styled";
import { useVideos, useProjects } from "../../../hooks/api";
import { formatDate } from "../../../helper/utility";
import { putVideoAPI } from "../../../api/admin.http.service";
import { useMediaQuery } from "../../../hooks";
import VideoAdd from "./VideoAdd";

const columns = [
  {
    title: "ID",
    dataIndex: "videoId",
    key: "videoId",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "thumbnail",
    dataIndex: "thumbnail",
    key: "thumbnail",
  },
  {
    title: "Date",
    dataIndex: "publishedAt",
    key: "publishedAt",
  },
  {
    title: "ignored",
    dataIndex: "ignored",
    key: "ignored",
  },
];

const Videos = () => {
  const { videos: fetchedVideos, fetchVideos } = useVideos();
  const { projects, fetchProjects } = useProjects();
  const [videos, setVideos] = useState(fetchedVideos);
  const [filters, setFilters] = useState({});
  const [isAddVideoVisible, setIsAddVideoVisible] = useState(false);
  const isMobileWindow = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    setVideos(fetchedVideos);
  }, [fetchedVideos]);

  const getVideos = (params) => {
    fetchVideos(params);
  };

  useEffect(() => {
    getVideos({});
    fetchProjects();
  }, []);

  const onClearAllFilters = () => {
    setFilters({});
    getVideos({});
  };

  const handleOnChange = (type, value) => {
    let finalFilters;

    if (value === "") {
      finalFilters = omit(filters, type);
      setFilters(finalFilters);
    } else {
      finalFilters = { ...filters, [type]: value };
      setFilters(finalFilters);
    }
    getVideos(finalFilters);
  };

  const onUpdateVideo = (id, data) => {
    const tmpVideos = [];

    videos.forEach((video) => {
      if (video.id === id) {
        tmpVideos.push({ ...video, ...data });
      } else {
        tmpVideos.push(video);
      }
    });

    setVideos(tmpVideos);
  };

  const onChangeIgnored = (id, isIgnored) => {
    const body = { ignored: isIgnored };
    putVideoAPI({
      body,
      subpath: { id },
      headers: { "Content-type": "application/json" },
    });
    onUpdateVideo(id, body);
  };

  const onCloseAddModal = (isAdded) => {
    setIsAddVideoVisible(false);
    isAdded && onClearAllFilters();
  };

  const videosTableData = [];

  videos?.map((video) => {
    const {
      id,
      videoId,
      title,
      description,
      thumbnail,
      thumbnail_hq,
      publishedAt,
      ignored,
    } = video;

    return videosTableData.push({
      key: id,
      videoId,
      title,
      description,
      thumbnail,
      thumbnail_hq,
      publishedAt: publishedAt
        ? formatDate(publishedAt, "MM/dd/yyyy hh:mm:ss a")
        : "",
      ignored: (
        <Switch
          value={ignored}
          onChange={(isIgnored) => onChangeIgnored(id, isIgnored)}
        />
      ),
    });
  });

  if (!videos) {
    return null;
  }

  const itemSeparator = isMobileWindow
    ? { marginBottom: "10px" }
    : { marginRight: "10px", width: "60%" };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Videos"
          subTitle={
            <>
              <Select
                value={filters.project_id || ""}
                onChange={(e) => handleOnChange("project_id", e.target.value)}
                style={itemSeparator}
              >
                <option key="select_project" value="">
                  Select Project
                </option>
                {projects?.map(({ id, Artist }) => (
                  <option key={id} value={id}>
                    {Artist || ""}
                  </option>
                ))}
              </Select>
              <TableButton
                className="btn-add_new"
                size="default"
                type="primary"
                onClick={onClearAllFilters}
              >
                Clear
              </TableButton>
            </>
          }
          buttons={
            projects && [
              <TableButton
                onClick={() => setIsAddVideoVisible(true)}
                className="btn-add_new"
                size="default"
                type="primary"
                key="1"
              >
                + Add New Video
              </TableButton>,
            ]
          }
        />
      </CardToolbox>
      <Table
        dataSource={videosTableData}
        columns={columns}
        pagination={false}
      />
      <VideoAdd
        isVisible={isAddVideoVisible}
        onCloseModal={onCloseAddModal}
        projects={projects}
      />
    </>
  );
};

export default Videos;

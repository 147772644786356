import { useState, useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { ArtistContext } from "../../../context";
import { useMediaQuery } from "../../../hooks";
import Seek from "./Seek";

const animate = keyframes`
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const List = styled.ul`
  list-style: none;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0;

  li {
    animation: ${animate} 1.5s ease 1s forwards;
    &:first-child {
      font-weight: 600;
      font-size: 18px;
    }
    a {
      color: #1e8fc4;
      text-decoration: underline;
    }
  }
`;

const contactFields = [
  "contact1_id",
  "contact2_id",
  "contact3_id",
  "contact4_id",
];

const Contacts = () => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { selectedArtist } = useContext(ArtistContext);
  const [contacts, setContacts] = useState([]);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);

  useEffect(() => {
    if (selectedArtist) {
      const listOfContacts = [];

      contactFields.forEach((field) => {
        if (selectedArtist[field]) {
          listOfContacts.push(selectedArtist[field]);
        }
      });

      setContacts(listOfContacts);
      setSelectedContactIndex(0);
    }
  }, [selectedArtist]);

  if (contacts.length === 0) {
    return null;
  }

  const onClickPrev = () => {
    const val = selectedContactIndex - 1;
    setSelectedContactIndex(val === -1 ? contacts.length - 1 : val);
  };
  const onClickNext = () => {
    setSelectedContactIndex((selectedContactIndex + 1) % contacts.length);
  };

  const contact = contacts[selectedContactIndex];

  return (
    <>
      <Seek
        isTransparent={!isMobileWindow}
        items={contacts}
        onClickPrev={onClickPrev}
        contacts
        onClickNext={onClickNext}
      />
      <div
        style={{
          padding: "20px",
          ...(!isMobileWindow && { paddingLeft: "54px", paddingTop: "0px" }),
        }}
      >
        <List>
          <li>{contact.Name || ""}</li>
          <li>{contact.PrimaryActivity || ""}</li>
          <li>{contact.Company || ""}</li>
          <li>{contact.Address || ""}</li>
          <li>{`${contact.City}, ${contact.State} ${contact.PostalCode}`}</li>
          <li>{contact.Country || ""}</li>
        </List>
        <List>
          <li></li>
          <li>{contact.Phone && `Phone: ${contact.Phone}`}</li>
          <li>{contact.Phone2 && `Phone: ${contact.Phone2}`}</li>
          <li>{contact.Fax && `Fax: ${contact.Fax}`}</li>
          <li>{contact.Email && `Email: ${contact.Email}`}</li>
          <li>{contact.Email2 && `Email: ${contact.Email2}`}</li>
          <li>{contact.Email3 && `Email: ${contact.Email3}`}</li>
          <li>
            Web:{" "}
            {contact.Website && <a href={contact.Website}>{contact.Website}</a>}
          </li>
        </List>
      </div>
    </>
  );
};

export default Contacts;

import { useState, useEffect } from "react";

import useFetchAPI from "../useFetchAPI";
import { getGenresAPI, getCountriesAPI } from "../../api/project.http.service";

const useSearchFilterData = () => {
  const [{ response: genresResponse }, fetchGenres] = useFetchAPI();
  const [{ response: countriesResponse }, fetchCountries] = useFetchAPI();

  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState(undefined);

  useEffect(() => {
    fetchGenres({
      api: getGenresAPI,
      payload: {},
    });
    fetchCountries({
      api: getCountriesAPI,
      payload: {},
    });
  }, []);

  useEffect(() => {
    if (genresResponse && countriesResponse) {
      let genres = [];
      let countries = [];
      let artistNames = [];

      if (genresResponse.status === "ok") {
        genres = genresResponse.genres;
      }
      if (countriesResponse.status === "ok") {
        countries = countriesResponse.countries;
      }

      setResponse({ genres, countries, artistNames });
      setIsLoading(false);
    }
  }, [genresResponse, countriesResponse]);

  return { isLoading, response };
};

export default useSearchFilterData;

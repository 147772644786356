import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { useCreatePlaylistShareKey } from "../../../hooks/api";

const autoShow = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const ShareContainer = styled.div`
  position: absolute;
  margin: 0 10%;
  bottom: 100px;
  width: 80%;
  padding: 10px;
  text-align: center;
  word-wrap: break-word;
  background-color: #a8d191;
  border-radius: 4px;
  color: #32750c;

  animation: ${autoShow} 4s linear forwards;
`;

const SharePlaylist = ({ isVisible, onClose }) => {
  const [shouldShowLink, setShouldShowLink] = useState(false);
  const shareKey = useCreatePlaylistShareKey(isVisible);

  const link = `https://access.myhitonline.com/playlist/${shareKey}`;
  const message = `Link copied! A link to this playlist has been saved to your clipboard. Simply paste it anywhere you want to share the playlist (${link}).`;

  useEffect(() => {
    if (isVisible) {
      if (shareKey) {
        navigator.clipboard.writeText(link);
        setShouldShowLink(true);
      }
    } else {
      setShouldShowLink(false);
    }
  }, [isVisible, shareKey]);

  return shouldShowLink ? (
    <ShareContainer onAnimationEnd={() => onClose()}>{message}</ShareContainer>
  ) : null;
};

export default SharePlaylist;

import { useState, useEffect } from "react";

import { getProjectAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useProjects = () => {
  const [projects, setProjects] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  const fetchProjects = () => {
    fetch({
      api: getProjectAPI,
      payload: { params: {} },
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      if (response) {
        setProjects(response);
      } else {
        setProjects([]);
      }
    }
  }, [isLoading]);

  return { projects, fetchProjects };
};

export default useProjects;

import { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import omit from "lodash/omit";

import { Table, TableButton, PageHeader, RangePicker } from "../../../commonUI";
import { CardToolbox, Input, Select } from "../../../styled";
import { useUsers } from "../../../hooks/api";
import { useMediaQuery } from "../../../hooks";
import { formatDate } from "../../../helper/utility";
import UserDetail from "./UserDetail";
import UserAdd from "./UserAdd";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Registered",
    dataIndex: "registered",
    key: "registered",
  },
  {
    title: "Membership Type",
    dataIndex: "membership_type",
    key: "membership_type",
  },
  {
    title: "Expiration Date",
    dataIndex: "expiration_date",
    key: "expiration_date",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Audits",
    dataIndex: "audits",
    key: "audits",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const mobileColumns = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const Users = () => {
  const { users: fetchedUsers, fetchUsers, total, plans } = useUsers();
  const [currentPage, setCurrentPage] = useState(1);
  const [isUserDetailVisible, setIsUserDetailVisible] = useState(false);
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [users, setUsers] = useState(fetchedUsers);
  const [userDetail, setUserDetail] = useState(null);
  const [filters, setFilters] = useState({});
  const [emailName, setEmailName] = useState("");
  const [range, setRange] = useState([null, null]);
  const isMobileWindow = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    setUsers(fetchedUsers);
  }, [fetchedUsers]);

  const getUsers = (params) => {
    fetchUsers(params);
  };

  useEffect(() => {
    getUsers({ page: currentPage, ...filters });
  }, [currentPage]);

  const onCloseAddModal = (isAdded) => {
    setIsAddUserVisible(false);
    isAdded && onClearAllFilters();
  };
  const onCloseDetailModal = () => {
    setIsUserDetailVisible(false);
    setUserDetail(null);
  };
  const onUpdateUser = (id, data) => {
    const tmpUsers = [];

    users.forEach((user) => {
      if (user.id === id) {
        tmpUsers.push({ ...user, ...data });
      } else {
        tmpUsers.push(user);
      }
    });

    setUsers(tmpUsers);
  };

  const onChangePagination = ({ current }) => {
    setCurrentPage(current);
  };
  const onChangeRowSelection = (keys, rows) => {
    console.log("keys", keys, "rows", rows);
  };
  const handleOnChange = (type, value) => {
    let finalValue = value;

    if (type === "daterange") {
      finalValue = `${value[0]} - ${value[1]}`;
    }

    let finalFilters;

    if (finalValue === "") {
      finalFilters = omit(filters, type);
      setFilters(finalFilters);
    } else {
      finalFilters = { ...filters, [type]: finalValue };
      setFilters(finalFilters);
    }
    getUsers({ page: currentPage, ...finalFilters });
  };
  const onClearAllFilters = () => {
    setFilters({});
    setEmailName("");
    setRange([null, null]);
    getUsers({ page: 1 });
  };

  const usersTableData = [];

  users?.map((user) => {
    const {
      id,
      name,
      registered,
      membership_type,
      expiration_date,
      email,
      email2,
      audits,
    } = user;

    const onClick = () => {
      setIsUserDetailVisible(true);
      setUserDetail(user);
    };

    if (isMobileWindow) {
      return usersTableData.push({
        key: id,
        email: (
          <>
            {email || ""}
            <br />
            {email2 || ""}
          </>
        ),
        action: (
          <TableButton
            onClick={onClick}
            disabled={isUserDetailVisible || isAddUserVisible}
            className="btn-icon"
            type="primary"
            to="#"
            shape="circle"
          >
            <FeatherIcon icon="eye" size={16} />
          </TableButton>
        ),
      });
    }

    return usersTableData.push({
      key: id,
      name,
      registered: formatDate(registered, "MM/dd/yyyy"),
      membership_type: membership_type || "",
      expiration_date: expiration_date
        ? formatDate(expiration_date, "MM/dd/yyyy")
        : "",
      email: (
        <>
          {email || ""}
          <br />
          {email2 || ""}
        </>
      ),
      audits: audits || "A",
      action: (
        <TableButton
          onClick={onClick}
          disabled={isUserDetailVisible || isAddUserVisible}
          className="btn-icon"
          type="primary"
          to="#"
          shape="circle"
        >
          <FeatherIcon icon="eye" size={16} />
        </TableButton>
      ),
    });
  });

  if (!users) {
    return null;
  }

  const itemSeparator = isMobileWindow
    ? { marginBottom: "10px" }
    : { marginRight: "10px", width: "30%" };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Users"
          subTitle={
            <>
              <Select
                value={filters.membership_type || ""}
                onChange={(e) =>
                  handleOnChange("membership_type", e.target.value)
                }
                style={itemSeparator}
              >
                <option key="any_plan" value="">
                  Any Plan
                </option>
                {plans.map(({ key, title, id }) => (
                  <option key={key} value={id}>
                    {title}
                  </option>
                ))}
              </Select>
              <Input
                value={emailName}
                onChange={(e) => setEmailName(e.target.value)}
                onKeyPress={(e) =>
                  e.which === 13 && handleOnChange("email", e.target.value)
                }
                style={itemSeparator}
                placeholder="Email, Name"
                type="text"
              />
              <RangePicker
                placeholder={["Exp start date", "Exp end date"]}
                value={range}
                onChange={(dateArr, dateStrArr) => {
                  setRange(dateArr);
                  handleOnChange("daterange", dateStrArr);
                }}
                format="MM/DD/YYYY"
                style={
                  isMobileWindow
                    ? { marginBottom: "10px" }
                    : { marginRight: "10px", width: "60%" }
                }
              />
              <TableButton
                className="btn-add_new"
                size="default"
                type="primary"
                onClick={onClearAllFilters}
              >
                Clear
              </TableButton>
            </>
          }
          buttons={[
            <TableButton
              className="btn-add_new"
              size="default"
              type="primary"
              key="1"
            >
              Delete selected
            </TableButton>,
            <TableButton
              onClick={() => setIsAddUserVisible(true)}
              className="btn-add_new"
              size="default"
              type="primary"
              key="2"
            >
              + Add New User
            </TableButton>,
          ]}
        />
      </CardToolbox>
      <Table
        dataSource={usersTableData}
        columns={isMobileWindow ? mobileColumns : columns}
        pagination={{
          defaultPageSize: 20,
          total,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSizeOptions: [],
        }}
        onChange={onChangePagination}
        rowSelection={{
          type: "checkbox",
          onChange: onChangeRowSelection,
        }}
      />
      <UserDetail
        isVisible={isUserDetailVisible}
        onCloseModal={onCloseDetailModal}
        userDetail={userDetail}
        plans={plans}
        onUpdateUser={onUpdateUser}
      />
      <UserAdd isVisible={isAddUserVisible} onCloseModal={onCloseAddModal} />
    </>
  );
};

export default Users;

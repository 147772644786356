import { useState, useEffect, useRef, useCallback } from "react";
import { HslStringColorPicker } from "react-colorful";
import styled from "styled-components";

import { useDebounce, useClickOutside, useDidUpdateEffect } from "../hooks";

const DEFAULT_WHITE_COLOR = "hsl(0, 0%, 100%)";
const DEFAULT_BLACK_COLOR = "hsl(0, 0%, 0%)";

const StyledPicker = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border: 10px solid #eee;
  border-radius: 4px;
  background-color: ${({ selectedColor }) => selectedColor};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%); */
  }
`;
const Popover = styled.div`
  position: absolute;
  top: 26px;
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
`;

const getHslString = (value) => {
  if (!value || value === "white") {
    return DEFAULT_WHITE_COLOR;
  }
  if (value === "black") {
    return DEFAULT_BLACK_COLOR;
  }
  return value;
};

const Picker = ({ selectedColor, onChange }) => {
  const hslString = getHslString(selectedColor);
  const [isShowingPicker, setIsShowingPicker] = useState(false);
  const [color, setColor] = useState(hslString);
  const debouncedColor = useDebounce(color, 300);
  const popoverRef = useRef();

  const close = useCallback(() => setIsShowingPicker(false), []);
  useClickOutside(popoverRef, close);

  useDidUpdateEffect(() => {
    onChange(debouncedColor);
  }, [debouncedColor]);

  useEffect(() => {
    setColor(hslString);
  }, [hslString]);

  return (
    <StyledPicker
      selectedColor={color}
      onClick={() => setIsShowingPicker(!isShowingPicker)}
    >
      {isShowingPicker ? (
        <Popover ref={popoverRef}>
          <HslStringColorPicker color={hslString} onChange={setColor} />
        </Popover>
      ) : null}
    </StyledPicker>
  );
};

export default Picker;

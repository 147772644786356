import { StyledNavItem, StyledLink } from "./styles";

const NavItem = ({
  href = "javascript:void(0)",
  text = "",
  faded = false,
  onClick,
}) => {
  return (
    <StyledNavItem>
      <StyledLink href={href} faded={faded} onClick={onClick}>
        {text}
      </StyledLink>
    </StyledNavItem>
  );
};

export default NavItem;

import { useState, useEffect } from "react";

import { putUserSongAPI } from "../../api/user.http.service";
import useFetchAPI from "../useFetchAPI";

const useUpdateSong = () => {
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const [result, setResult] = useState(undefined);

  const updateSong = (id, body) => {
    fetch({
      api: putUserSongAPI,
      payload: {
        body,
        subpath: { id },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  useEffect(() => {
    if (isLoading === false && response && response.status === "ok") {
      setResult(response.song[0]);
    }
  }, [isLoading]);

  return { result, updateSong };
};

export default useUpdateSong;

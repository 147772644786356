import { useEffect } from "react";
import { useState } from "react";

import {
  StyledDropdownItemsContainer,
  SuggestionBoxContainer,
  SuggestionInput,
  SearchIcon,
  Suggestion,
  CrossIcon,
} from "./styles";

const SuggestionSearchBox = ({
  size,
  suggestions,
  value,
  onChange,
  onSelect,
}) => {
  const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);
  const [typedInput, setTypedInput] = useState("");

  useEffect(() => {
    setTypedInput(value);
  }, [value]);

  const handleOnChange = (e) => {
    setTypedInput(e.target.value);
    onChange(e.target.value);
  };

  const handleOnClick = (value) => {
    setTypedInput(value);
    onSelect(value);
    setShouldShowSuggestions(false);
  };

  return (
    <SuggestionBoxContainer
      size={size}
      showingSuggestions={shouldShowSuggestions}
      onClick={() => setShouldShowSuggestions(true)}
      onMouseLeave={() => setShouldShowSuggestions(false)}
    >
      {typedInput ? <CrossIcon onClick={() => handleOnClick("")} /> : null}
      {shouldShowSuggestions ? (
        <SuggestionInput
          ref={(ref) => {
            ref && ref.focus();
          }}
          value={typedInput}
          onChange={handleOnChange}
        />
      ) : (
        <span>{typedInput || "Search projects, contacts..."}</span>
      )}
      <SearchIcon />
      <StyledDropdownItemsContainer
        isVisible={shouldShowSuggestions && suggestions.length}
      >
        {suggestions.map(({ title, data }) => {
          return (
            <>
              <Suggestion key={title} isTitle>
                {title}
              </Suggestion>
              {data.map((suggestion) => {
                const getHightlightSuggestion = () => {
                  if (typedInput) {
                    const regEx = new RegExp(`${typedInput}`, "gi");
                    const text = suggestion.replace(
                      regEx,
                      `<span style="color:#000;">${typedInput}</span>`
                    );
                    return `<span>${text}</span>`;
                  }
                  return suggestion;
                };
                return (
                  <Suggestion
                    key={suggestion}
                    onMouseDown={() => handleOnClick(suggestion)}
                    dangerouslySetInnerHTML={{
                      __html: getHightlightSuggestion(),
                    }}
                  />
                );
              })}
            </>
          );
        })}
      </StyledDropdownItemsContainer>
    </SuggestionBoxContainer>
  );
};

export default SuggestionSearchBox;

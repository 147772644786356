import { useState, useEffect } from "react";
import omit from "lodash/omit";

import { Table, TableButton, PageHeader, Switch } from "../../../commonUI";
import { CardToolbox, Input, Select } from "../../../styled";
import { useAlbums, useProjects } from "../../../hooks/api";
import { useFetchAPI } from "../../../hooks";
import { formatDate } from "../../../helper/utility";
import { putAlbumAPI } from "../../../api/admin.http.service";
import { useMediaQuery } from "../../../hooks";

const columns = [
  {
    title: "ID",
    dataIndex: "collectionId",
    key: "collectionId",
  },
  {
    title: "Name",
    dataIndex: "collectionName",
    key: "collectionName",
  },
  {
    title: "Genre",
    dataIndex: "primaryGenreName",
    key: "primaryGenreName",
  },
  {
    title: "thumbnail",
    dataIndex: "thumbnail",
    key: "thumbnail",
  },
  {
    title: "thumbnail_hq",
    dataIndex: "thumbnail_hq",
    key: "thumbnail_hq",
  },
  {
    title: "Date",
    dataIndex: "releaseDate",
    key: "releaseDate",
  },
  {
    title: "ignored",
    dataIndex: "ignored",
    key: "ignored",
  },
];

const Audits = () => {
  const { albums: fetchedAlbums, fetchAlbums } = useAlbums();
  const { projects, fetchProjects } = useProjects();
  const [albums, setAlbums] = useState(fetchedAlbums);
  const [_, updateAlbum] = useFetchAPI();
  const [filters, setFilters] = useState({});
  const [collectionName, setCollectionName] = useState("");
  const isMobileWindow = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    setAlbums(fetchedAlbums);
  }, [fetchedAlbums]);

  const getAlbums = (params) => {
    fetchAlbums(params);
  };

  useEffect(() => {
    getAlbums({});
    fetchProjects();
  }, []);

  const onClearAllFilters = () => {
    setFilters({});
    setCollectionName("");
    getAlbums({});
  };

  const handleOnChange = (type, value) => {
    let finalFilters;

    if (value === "") {
      finalFilters = omit(filters, type);
      setFilters(finalFilters);
    } else {
      finalFilters = { ...filters, [type]: value };
      setFilters(finalFilters);
    }
    getAlbums(finalFilters);
  };

  const onUpdateAlbum = (id, data) => {
    const tmpAlbums = [];

    albums.forEach((album) => {
      if (album.id === id) {
        tmpAlbums.push({ ...album, ...data });
      } else {
        tmpAlbums.push(album);
      }
    });

    setAlbums(tmpAlbums);
  };

  const onChangeIgnored = (id, isIgnored) => {
    const body = { ignored: isIgnored };

    updateAlbum({
      api: putAlbumAPI,
      payload: {
        body,
        subpath: { id },
        headers: { "Content-type": "application/json" },
      },
    });
    onUpdateAlbum(id, body);
  };

  const albumsTableData = [];

  albums?.map((album) => {
    const {
      id,
      collectionId,
      collectionName,
      primaryGenreName,
      thumbnail,
      thumbnail_hq,
      releaseDate,
      ignored,
    } = album;

    return albumsTableData.push({
      key: id,
      collectionId,
      collectionName,
      primaryGenreName,
      thumbnail,
      thumbnail_hq,
      releaseDate: releaseDate
        ? formatDate(releaseDate, "MM/dd/yyyy hh:mm:ss a")
        : "",
      ignored: (
        <Switch
          value={ignored}
          onChange={(isIgnored) => onChangeIgnored(id, isIgnored)}
        />
      ),
    });
  });

  if (!albums) {
    return null;
  }

  const itemSeparator = isMobileWindow
    ? { marginBottom: "10px" }
    : { marginRight: "10px", width: "50%" };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Albums"
          subTitle={
            <>
              <Select
                value={filters.artist_id || ""}
                onChange={(e) => handleOnChange("artist_id", e.target.value)}
                style={itemSeparator}
              >
                <option key="select_project" value="">
                  Select Project
                </option>
                {projects?.map(({ artist_id, Artist }) => (
                  <option key={artist_id} value={artist_id}>
                    {Artist || ""}
                  </option>
                ))}
              </Select>
              <Input
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                onKeyPress={(e) =>
                  e.which === 13 &&
                  handleOnChange("collectionName", e.target.value)
                }
                style={itemSeparator}
                placeholder="Search"
                type="text"
              />
              <TableButton
                className="btn-add_new"
                size="default"
                type="primary"
                onClick={onClearAllFilters}
              >
                Clear
              </TableButton>
            </>
          }
          buttons={[]}
        />
      </CardToolbox>
      <Table
        dataSource={albumsTableData}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default Audits;

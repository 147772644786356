import React from "react";
import { Link } from "react-router-dom";

function FourZeroFour() {
  return (
    <div className="container">
      <h2 className="text-center">
        Sorry! We couldn&apos;t find what you were looking for
      </h2>
      <div className="text-center">
        <Link to="/">Go back</Link>
      </div>
    </div>
  );
}

export default FourZeroFour;

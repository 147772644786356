import { useState, useEffect } from "react";

import { Modal, Switch, DatePicker } from "../../../commonUI";
import { Input, Select } from "../../../styled";
import { useUpdateUser } from "../../../hooks/api";

const allowedKeys = [
  "registered",
  "membership_updated",
  "membership_type",
  "expiration_date",
  "is_admin",
  "is_active",
  "sps",
];

const UserDetail = ({
  isVisible,
  onCloseModal,
  userDetail,
  plans,
  onUpdateUser,
}) => {
  const [detail, setDetail] = useState(userDetail);
  const updateUser = useUpdateUser();

  useEffect(() => {
    setDetail(userDetail);
  }, [userDetail]);

  const fetch = (body) => {
    updateUser({
      body,
      subpath: { id: userDetail.id },
      headers: {
        "Content-type": "application/json",
      },
    });
  };

  const onUpdate = (type, value) => {
    let submitValue = value !== undefined ? value : detail[type];

    if (type === "membership_type" && submitValue === "") {
      submitValue = null;
    }
    if (type === "membership_updated" || type === "expiration_date") {
      submitValue = submitValue.toISOString();
    }

    fetch({ [type]: submitValue });
    onUpdateUser(userDetail.id, { [type]: submitValue });
  };
  const handleOnChange = (type, value) => {
    setDetail({ ...detail, [type]: value });

    if (allowedKeys.includes(type)) {
      onUpdate(type, value);
    }
  };
  const handleOnKeyPress = (type, e) => {
    if (e.which === 13) {
      onUpdate(type, e.target.value);
    }
  };

  if (!detail) {
    return null;
  }

  const getInputProps = (key) => ({
    value: detail[key] || "",
    onChange: (e) => handleOnChange(key, e.target.value),
    onBlur: () => onUpdate(key),
    onKeyPress: (e) => handleOnKeyPress(key, e),
  });

  return (
    <Modal isVisible={isVisible} backgroundColor="rgba(240, 243, 244, 0.8)">
      <Modal.Header
        icon="fa fa-envelope"
        title="User"
        onCloseModal={onCloseModal}
      />
      <Modal.Form>
        <Modal.FormGroup title="Status">
          <Input value={detail.status || ""} disabled />
        </Modal.FormGroup>
        <Modal.FormGroup title="Name">
          <Input {...getInputProps("name")} />
        </Modal.FormGroup>
        <Modal.FormGroup title="Email">
          <Input {...getInputProps("email")} />
        </Modal.FormGroup>
        <Modal.FormGroup title="Alternative Email">
          <Input {...getInputProps("email2")} />
        </Modal.FormGroup>
        <Modal.FormGroup title="Registered">
          <DatePicker
            value={detail.registered && new Date(detail.registered)}
            onChange={(date) => handleOnChange("registered", date)}
            format="MM/DD/YYYY"
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Membership Type">
          <Select
            value={userDetail?.membership_type || ""}
            onChange={(e) => handleOnChange("membership_type", e.target.value)}
          >
            <option key="select_membership_type" value="">
              Select membership type
            </option>
            {plans.map(({ key, title }) => (
              <option key={key} value={key}>
                {title}
              </option>
            ))}
          </Select>
        </Modal.FormGroup>
        <Modal.FormGroup title="Membership Updated">
          <DatePicker
            value={
              detail.membership_updated && new Date(detail.membership_updated)
            }
            onChange={(date) => handleOnChange("membership_updated", date)}
            format="MM/DD/YYYY"
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Expiration Date">
          <DatePicker
            value={detail.expiration_date && new Date(detail.expiration_date)}
            onChange={(date) => handleOnChange("expiration_date", date)}
            format="MM/DD/YYYY"
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Is Admin">
          <Switch
            value={!!detail.is_admin}
            onChange={(value) => handleOnChange("is_admin", value ? 1 : 0)}
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Is Active">
          <Switch
            value={!!detail.is_active}
            onChange={(value) => handleOnChange("is_active", value ? 1 : 0)}
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Song Pitch System">
          <Switch
            value={!!detail.sps}
            onChange={(value) => handleOnChange("sps", value ? 1 : 0)}
          />
        </Modal.FormGroup>
      </Modal.Form>
      <Modal.FormGroup title="Company Name">
        <Input value={detail.company_name || ""} disabled />
      </Modal.FormGroup>
    </Modal>
  );
};

export default UserDetail;

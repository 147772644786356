import { GET, POST, PUT, DELETE, FILE_UPLOAD } from "./api.http.service";

export const getUserInfoAPI = (payload) => GET("user/info")(payload);
export const putProfileUpdateAPI = (payload) =>
  PUT("user/profile_update")(payload);
export const getUserPlaylistAPI = (payload) => GET("userplaylist")(payload);
export const getUserPlaylistSharedAPI = (payload) =>
  GET(`userplaylist/shared/${payload.subpath.id}`)(payload);
export const postUserPlaylistAPI = (payload) => POST("userplaylist")(payload);
export const putUserPlaylistAPI = (payload) =>
  POST(`userplaylist/${payload.subpath.id}`)(payload);
export const deleteUserPlaylistAPI = (payload) =>
  DELETE(`userplaylist/${payload.subpath.id}`)(payload);
export const postUserPlaylistShareKeyAPI = (payload) =>
  POST(`userplaylist/${payload.subpath.id}/create_share_key`)(payload);
export const getUserOptionAPI = (payload) => GET("useroption")(payload);
export const putUserOptionAPI = (payload) => PUT("useroption")(payload);
export const putUserOptionUploadImageAPI = (payload) =>
  FILE_UPLOAD("useroption/upload_image")(payload);
export const getUserSongAPI = (payload) => GET("usersong")(payload);
export const putUserSongAPI = (payload) =>
  PUT(`usersong/${payload.subpath.id}`)(payload);
export const deleteUserSongAPI = (payload) =>
  DELETE(`usersong/${payload.subpath.id}`)(payload);
export const fileUploadUserSongAPI = (payload) =>
  FILE_UPLOAD("usersong")(payload);
export const postUserSongChangeOrderAPI = (payload) =>
  POST("usersong/change_order")(payload);

import {
  StyledSocialContainer,
  StyledSocialItem,
  StyledSocialIcon,
} from "./styles";

const Social = ({ isNavOpen }) => {
  return (
    <StyledSocialContainer isNavOpen={isNavOpen}>
      <StyledSocialIcon icon="fa fa-twitter" hideUnderline />
      <StyledSocialIcon icon="fa fa-facebook" hideUnderline />
      <StyledSocialItem href="/">SUPPORT</StyledSocialItem>
    </StyledSocialContainer>
  );
};

export default Social;

import { useState, useEffect, Children, cloneElement } from "react";
import { createPortal } from "react-dom";
import styled, { css, keyframes } from "styled-components";

import CloseIcon from "./CloseIcon";

const FormContainer = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 0px 15px 15px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 5px 10px 5px rgb(0, 0, 0, 30%);
  overflow-y: scroll;
  overflow-x: hidden;

  h1 {
    color: #000;
    font-size: 26px;
  }

  @media (min-width: 576px) {
    width: 80%;
  }
  @media (min-width: 992px) {
    width: 768px;
  }
`;
const StyledForm = styled.div`
  width: 90%;
  margin: 0 auto;
  color: #000;

  @media (max-width: 576px) {
    margin: 0;
    width: 10 0%;
  }
`;
const StyledFormGroup = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-bottom: 20px;
  padding: 0px 15px;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 576px) {
    padding: 0;
  }
`;

const moveDown = keyframes`
  from {
    top: -100%;
    opacity: 0.4;
  }
  to {
    top: 0%;
    opacity: 1;
  }
`;
const moveUp = keyframes`
  from {
    top: 0%;
    opacity: 0.4;
  }
  to {
    top: -100%;
    opacity: 1;
  }
`;
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "rgba(241, 184, 33, 80%)"};
  padding: 20px;
  z-index: 1;

  ${FormContainer} {
    margin: auto;
  }
  animation: ${({ isVisible }) => (isVisible ? moveDown : moveUp)} 0.2s linear
    forwards;
`;

const EqualPartition = css`
  width: 50%;
  box-sizing: border-box;
  display: inline-block;
`;
const ModalFormLabel = styled.label`
  ${EqualPartition}
  padding-right: 15px;
  font-weight: 700;

  @media (min-width: 768px) {
    text-align: right;
  }
`;
const ModalFormField = styled.div`
  ${EqualPartition}
  padding-left: 15px;
`;
const ModalBody = styled.div`
  width: 100%auto;
  padding: 20px;
  color: #000;
`;
const ModalSubmitContainer = styled.div`
  width: 100%;
  padding: 20px;
  text-align: right;

  > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
const ModalSubmitButton = styled.button.attrs(({ kind }) => ({
  type: "button",
  className: `btn btn-${kind}`,
}))`
  ${({ kind }) => kind === "default" && { border: "1px solid #ccc" }};
`;

const ModalHeader = ({ title = "", icon = "", onCloseModal }) => {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        paddingTop: "15px",
      }}
    >
      <CloseIcon onClick={onCloseModal} />
      <h1>
        <i className={icon} />
        &nbsp;{title}
      </h1>
    </div>
  );
};

const ModalForm = ({ children }) => {
  return <StyledForm>{children}</StyledForm>;
};
const ModalFormGroup = ({ type = "direct_edit", title, children }) => {
  const [isFieldClicked, setIsFieldClicked] = useState(false);

  const renderField = () => {
    const modifiedChildren = [];

    Children.forEach(children, (child) => {
      if (child.type === "span" && !isFieldClicked) {
        modifiedChildren.push(
          cloneElement(child, {
            ...child.props,
            onClick: () => {
              setIsFieldClicked(true);
              child.props.onClick && child.props.onClick();
            },
          })
        );
        return;
      }
      if (
        (child.type === "input" || child.type.target === "input") &&
        isFieldClicked
      ) {
        modifiedChildren.push(
          cloneElement(child, {
            ...child.props,
            onBlur: () => {
              setIsFieldClicked(false);
              child.props.onBlur && child.props.onBlur();
            },
          })
        );
        return;
      }
    });

    return modifiedChildren;
  };

  return (
    <StyledFormGroup>
      {title ? (
        <>
          <ModalFormLabel>{title}</ModalFormLabel>
          <ModalFormField>
            {type === "click_to_edit" ? renderField() : children}
          </ModalFormField>
        </>
      ) : (
        children
      )}
    </StyledFormGroup>
  );
};

const modalRoot = document.getElementById("modal-root");

const Modal = ({ backgroundColor, isVisible, children }) => {
  const [showContainer, setShowContainer] = useState(isVisible || false);

  useEffect(() => {
    if (isVisible) {
      setShowContainer(true);
    }
  }, [isVisible]);

  const handleOnAnimationEnd = () => {
    if (isVisible === false) {
      setShowContainer(false);
    }
  };

  if (!showContainer) {
    return null;
  }

  return createPortal(
    <Container
      backgroundColor={backgroundColor}
      isVisible={isVisible}
      onAnimationEnd={handleOnAnimationEnd}
    >
      <FormContainer>{children}</FormContainer>
    </Container>,
    modalRoot
  );
};

Modal.Header = ModalHeader;
Modal.Form = ModalForm;
Modal.FormGroup = ModalFormGroup;

export const ModalStyles = {
  Body: ModalBody,
  SubmitContainer: ModalSubmitContainer,
  SubmitButton: ModalSubmitButton,
};

export default Modal;

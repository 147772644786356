import styled from "styled-components";

const StyledAppContainer = styled.div`
  height: 100vh;
`;

const StyledSpan = styled.span``;

const StyledNavToggler = styled.button`
  position: fixed;
  z-index: 1;
  top: 23px;
  right: 10px;
  width: 30px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  border: none;
  outline: 0;
  background: 0 0;

  @media (min-width: 576px) {
    right: 27px;
  }

  ${StyledSpan} {
    ${({ isNavOpen }) => isNavOpen && `opacity: 0;`}
    background: #5f656f;

    position: absolute;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 2px;
    text-indent: 200%;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.25s;
    transition: transform 0.25s;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #5f656f;
  }
  &:before {
    ${({ isNavOpen }) =>
      isNavOpen
        ? `
      -webkit-transform: rotate3d(0, 0, 1, 45deg);
      transform: rotate3d(0, 0, 1, 45deg);`
        : `
      -webkit-transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);
      transform: translate3d(0, -10px, 0) scale3d(0.8, 1, 1);`}
  }
  &:after {
    ${({ isNavOpen }) =>
      isNavOpen
        ? `
      -webkit-transform: rotate3d(0, 0, 1, -45deg);
      transform: rotate3d(0, 0, 1, -45deg);`
        : `
      -webkit-transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);
      transform: translate3d(0, 10px, 0) scale3d(0.8, 1, 1);`}
  }
`;

export { StyledAppContainer, StyledSpan, StyledNavToggler };

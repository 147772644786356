import styled from "styled-components";

const StyledAdminHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px 10px;
  height: 50px;
  background-color: #f0f3f4;
  align-items: center;
  box-shadow: 0 2px 2px rgb(0 0 0 / 5%), 0 1px 0 rgb(0 0 0 / 5%);

  a {
    padding: 5px 10px;
    color: inherit;
  }
`;

export { StyledAdminHeader };

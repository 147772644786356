import { useState, useEffect, useContext } from "react";

import { putUserPlaylistAPI } from "../../api/user.http.service";
import PlaylistContext from "../../components/Playlist/PlaylistContext";
import useFetchAPI from "../useFetchAPI";

const useUpdatePlaylist = () => {
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const { userPlaylist, fetchPlaylist, onChangePlaylist } =
    useContext(PlaylistContext);
  const [isSuccessful, setIsSuccessful] = useState(undefined);

  const updatePlaylist = (id, name) => {
    fetch({
      api: putUserPlaylistAPI,
      payload: {
        body: { name },
        subpath: { id },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  useEffect(() => {
    if (isSuccessful === false) {
      if (userPlaylist && userPlaylist.length) {
        onChangePlaylist(0, userPlaylist[0]);
      }
      setIsSuccessful(true);
    }
  }, [userPlaylist]);

  useEffect(() => {
    if (isLoading === false && response) {
      fetchPlaylist();
      setIsSuccessful(false);
    }
  }, [isLoading]);

  return { isSuccessful, updatePlaylist };
};

export default useUpdatePlaylist;

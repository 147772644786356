import { useState, useEffect, useRef } from "react";

import { deleteUserSongAPI } from "../../api/user.http.service";
import useFetchAPI from "../useFetchAPI";

const useDeleteSong = () => {
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const [isSuccess, setIsSuccess] = useState(undefined);
  const deletedId = useRef();

  const deleteSong = (id) => {
    deletedId.current = id;
    fetch({
      api: deleteUserSongAPI,
      payload: {
        subpath: { id },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  useEffect(() => {
    if (isLoading === false && response && response.status === "ok") {
      setIsSuccess(true);
    }
  }, [isLoading]);

  return { isSuccess, deletedId, deleteSong };
};

export default useDeleteSong;

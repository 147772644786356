import { useState } from "react";
import styled from "styled-components";

import SubHeading from "./SubHeadings";
import Contacts from "./Contacts";
import ProjectBrief from "./ProjectBrief";
import Video from "./Videos";
import Collections from "./Collections";
import { GoBackIcon } from "./styles";

const DetailHeader = styled.div`
  height: 70px;
  background-color: #fff;
  padding-left: 20px;

  &::before,
  ${GoBackIcon} {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    content: "";
    width: 0;
    height: 100%;
  }

  @media (max-width: 767.98px) {
    display: none;
  }
`;
const DetailContainerOtherDevice = styled.div.attrs({
  className: "col-lg-10 offset-lg-1 col-xl-8 offset-xl-2",
})`
  @media (max-width: 767.98px) {
    display: none;
  }
`;
const ArtistProfileContainer = styled.div`
  width: 100%;
  display: flex;
`;
const ArtistImage = styled.div`
  background-image: url(${({ imgUrl }) => imgUrl});
  width: 320px;
  height: 180px;
  margin-left: 90px;
`;
const ArtistLead = styled.div`
  margin-left: 35px;
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ArtistRecentUpdate = styled.span`
  &:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1bb40b;
    margin-right: 5px;
  }
`;
const ArtistTitle = styled.h1`
  color: #4a4a4a;
  font-size: 54px;
  margin-bottom: 0px;
`;
const Genre = styled.span`
  margin-left: 10px;
  text-transform: uppercase;
`;
const OfficialSite = styled.a`
  color: #337ab7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const DetailOtherDevice = ({ selectedArtist, onClickBack }) => {
  const [subSection, setSubSection] = useState("");

  const handleOnClickSubHeading = (type) => {
    if (type !== "close") {
      setSubSection(type);
    }
  };

  const _renderSubSection = () => {
    switch (subSection) {
      case "contact":
        return <Contacts />;
      case "brief":
        return <ProjectBrief />;
      case "video":
        return <Video />;
      case "music":
        return <Collections />;
      default:
        return null;
    }
  };
  return (
    <>
      <DetailHeader>
        <GoBackIcon onClick={onClickBack}>
          <i className="fa fa-angle-left" />
        </GoBackIcon>
      </DetailHeader>
      <DetailContainerOtherDevice>
        <ArtistProfileContainer>
          <ArtistImage imgUrl={selectedArtist.thumbnail} />
          <ArtistLead>
            <div>
              {selectedArtist.Status !== "Active" ? (
                <ArtistRecentUpdate />
              ) : null}
              <ArtistTitle>{selectedArtist.Artist || ""}</ArtistTitle>
            </div>
            <div>
              {selectedArtist.Genre1 ? (
                <Genre>{selectedArtist.Genre1}</Genre>
              ) : null}
              {selectedArtist.Genre2 ? (
                <Genre>{selectedArtist.Genre2}</Genre>
              ) : null}
            </div>
            <h2 style={{ marginBottom: "0px" }}>
              <OfficialSite href={selectedArtist.Website} target="_blank">
                Official Site
              </OfficialSite>
            </h2>
          </ArtistLead>
        </ArtistProfileContainer>
        <div className="d-flex">
          <SubHeading
            selectedArtist={selectedArtist}
            selectedHeading={subSection}
            onClick={handleOnClickSubHeading}
          />
          <div style={{ width: "60%" }}>{_renderSubSection()}</div>
        </div>
      </DetailContainerOtherDevice>
    </>
  );
};

export default DetailOtherDevice;

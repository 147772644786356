import { useState } from "react";

import { Icon } from "./styles";
import PlaylistForm from "./PlaylistForm";

const CreatePlaylist = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Icon bg="transparent" onClick={() => setIsVisible(true)}>
        <i className="fa fa-plus" />
      </Icon>
      <PlaylistForm
        type="add"
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </>
  );
};

export default CreatePlaylist;

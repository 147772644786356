import { useState, useEffect, useRef, useContext } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ProgressBar } from "../../../commonUI";
import { useUpdateSong, useDeleteSong, useUserSongs } from "../../../hooks/api";
import {
  Container,
  StyledSongs,
  StyledSongContainer,
  StyledSong,
  ProgressBarContainer,
  StyledSongTitleContainer,
  DragIcon,
  Duration,
} from "./styles";
import SongsInfoModal from "./SongsInfoModal";
import AddSong from "./AddSong";
import PlaylistContext from "../PlaylistContext";

const getProgress = (info) => {
  if (!info) {
    return 0;
  }

  const { duration, currentTime } = info;
  return Math.ceil((currentTime / duration) * 100);
};
const getIndex = (list, id) => {
  let idx = -1;

  list.some((item, index) => {
    if (item.id === id) {
      idx = index;
      return true;
    }
    return false;
  });
  return idx;
};
const arrayReorder = (list, oldIndex, newIndex) => {
  const a = [...list];
  const oldData = a[oldIndex];
  a.splice(oldIndex, 1);

  return [...a.slice(0, newIndex), oldData, ...a.slice(newIndex)];
};

const Song = ({
  index,
  onUpdate,
  onDelete,
  settings,
  isRegisteredUser,
  ...rest
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    overIndex,
  } = useSortable({ id: rest.id });
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();
  const [songDetail, setSongDetail] = useState(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const { isSongDragable } = useContext(PlaylistContext);
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const progressBarWidth = useRef();
  const handleProgressEvent = () => {
    const { duration, currentTime, ended } = audioRef.current;

    ended && setIsPlaying(false);

    setSongDetail({
      duration: Math.floor(duration),
      currentTime: Math.floor(currentTime),
    });
  };
  // const handleSeekEvent = (event) => {
  //   const { duration, currentTime, ended } = audioRef.current;
  //   console.log("seek", event);
  //   console.log("seek ref", audioRef.current);
  // };

  useEffect(() => {
    audioRef.current = new Audio(rest.url);
    audioRef.current.addEventListener("timeupdate", handleProgressEvent);
    // audioRef.current.addEventListener("seeked", handleSeekEvent);

    return () => {
      audioRef.current.removeEventListener("timeupdate", handleProgressEvent);
    };
  }, []);

  const onClickPlay = () => {
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };
  const handleOnSeek = (e) => {
    const percentageSeeked =
      e.nativeEvent.offsetX / Number(progressBarWidth.current);
    const { duration } = audioRef.current;

    audioRef.current.currentTime = percentageSeeked * duration;
  };
  const handleOnUpdate = (body) => onUpdate(rest.id, body);
  const handleOnDelete = () => onDelete(rest.id);

  const order = index + 1;

  return (
    <StyledSongContainer ref={setNodeRef} isOver={overIndex === index}>
      <StyledSong style={style} settings={settings}>
        {isSongDragable ? (
          <DragIcon settings={settings} {...attributes} {...listeners}>
            <path d="M0 0h2v2H0zm5 0h2v2H5zm5 0h2v2h-2zM0 5h2v2H0zm5 0h2v2H5zm5 0h2v2h-2zM0 10h2v2H0zm5 0h2v2H5zm5 0h2v2h-2z" />
          </DragIcon>
        ) : null}
        <i
          onClick={onClickPlay}
          className={isPlaying ? "fa fa-square" : "fa fa-play-circle"}
        />
        <StyledSongTitleContainer>
          <span>{`${order > 9 ? order : `0${order}`} ${rest.title}`}</span>
        </StyledSongTitleContainer>
        <i onClick={() => setIsVisible(true)} className="fa fa-ellipsis-v" />
      </StyledSong>
      <ProgressBarContainer>
        <ProgressBar
          barRef={(ref) =>
            (progressBarWidth.current = ref ? ref.offsetWidth : undefined)
          }
          isVisible={isPlaying}
          progress={getProgress(songDetail)}
          seekable
          onSeek={handleOnSeek}
        />
        {isPlaying && songDetail ? (
          <Duration
            settings={settings}
          >{`${songDetail.currentTime}s/${songDetail.duration}s`}</Duration>
        ) : null}
      </ProgressBarContainer>
      <SongsInfoModal
        key={rest.id}
        isVisible={isVisible}
        onCloseModal={() => setIsVisible(false)}
        info={rest}
        onUpdate={handleOnUpdate}
        onDelete={handleOnDelete}
        isRegisteredUser={isRegisteredUser}
      />
    </StyledSongContainer>
  );
};

const Songs = ({ settings, isRegisteredUser }) => {
  const userSongs = useUserSongs();
  const [songs, setSongs] = useState(userSongs);
  const { result, updateSong } = useUpdateSong();
  const { isSuccess, deletedId, deleteSong } = useDeleteSong();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    setSongs(userSongs);
  }, [userSongs]);

  useEffect(() => {
    if (result) {
      const updatedSongs = [];
      songs.forEach((song) => {
        if (song.id === result.id) {
          updatedSongs.push(result);
          return;
        }
        updatedSongs.push(song);
      });

      setSongs(updatedSongs);
    }
  }, [result]);

  useEffect(() => {
    if (isSuccess) {
      setSongs(songs.filter((song) => song.id !== deletedId));
    }
  }, [isSuccess]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSongs((songs) => {
        const oldIndex = getIndex(songs, active.id);
        const newIndex = getIndex(songs, over.id);

        const modifiedArr = arrayReorder(songs, oldIndex, newIndex);
        return modifiedArr;
      });
    }
  };
  const onAddSongSuccess = (data) => {
    setSongs([data, ...songs]);
  };

  return (
    <Container>
      {isRegisteredUser ? (
        <AddSong settings={settings} onSuccess={onAddSongSuccess} />
      ) : null}
      <StyledSongs settings={settings}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={songs} strategy={verticalListSortingStrategy}>
            {songs.map((song, index) => (
              <Song
                key={song.id}
                {...song}
                index={index}
                settings={settings}
                onUpdate={updateSong}
                onDelete={deleteSong}
                isRegisteredUser={isRegisteredUser}
              />
            ))}
          </SortableContext>
        </DndContext>
      </StyledSongs>
    </Container>
  );
};

export default Songs;

import { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";

import { handleFetch, manipulateFetchedData } from "../helper/fetchUtility";
import { ERROR_MESSAGE, TOKEN_EXPIRED_MESSAGE } from "../api/api.http.service";
import { authAction } from "../redux/actions";
import useIsMounted from "./useIsMounted";

const inititalState = {
  isLoading: null,
  error: null,
  response: null,
};
const dataFetchReducer = (state = inititalState, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, response: null, error: null };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        response: action.payload,
        error: null,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, ...action.payload };
    default:
      return inititalState;
  }
};

const useFetchAPI = () => {
  const [{ api, payload }, setAPI] = useState({});
  const [{ isLoading, error, response }, dispatch] = useReducer(
    dataFetchReducer,
    inititalState
  );
  const storeDispatch = useDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (api && payload) {
      const fetchData = async () => {
        dispatch({ type: "FETCH_INIT" });
        let result;
        try {
          result = await handleFetch({ api, payload });
          manipulateFetchedData({ isMounted, result, dispatch });
        } catch (error) {
          if (error.message === TOKEN_EXPIRED_MESSAGE) {
            storeDispatch({ type: authAction.LOGOUT_SUCCESS });
            return;
          }
          isMounted &&
            dispatch({
              type: "FETCH_FAILURE",
              payload: {
                response: result,
                error: error.message || ERROR_MESSAGE,
              },
            });
        }
      };

      fetchData();
    }
  }, [api, payload]);

  return [{ isLoading, error, response }, setAPI];
};

export default useFetchAPI;

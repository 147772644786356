import { useEffect, useContext } from "react";
import { fileUploadUserSongAPI } from "../../../api/user.http.service";
import { useFetchAPI } from "../../../hooks";
import PlaylistContext from "../PlaylistContext";
import { StyledAddSong } from "../styles";

const AddSong = ({ settings, onSuccess }) => {
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const { selectedPlaylist } = useContext(PlaylistContext);
  const playlistId = selectedPlaylist && selectedPlaylist.data.id;

  const handleOnChangeFile = (e) => {
    fetch({
      api: fileUploadUserSongAPI,
      payload: {
        body: {
          playlist_id: playlistId,
          song: e.target.files[0],
        },
      },
    });
  };

  useEffect(() => {
    if (isLoading === false && response && response.status === "ok") {
      console.log("response after upload", response);
      onSuccess(response.song);
    }
  }, [isLoading]);

  if (playlistId) {
    return (
      <StyledAddSong settings={settings} className="fa fa-plus">
        <input type="file" accept="audio/mp3" onChange={handleOnChangeFile} />
      </StyledAddSong>
    );
  }

  return null;
};

export default AddSong;

import styled from "styled-components";

import { closeB } from "../assets/images";

const StyledIcon = styled.div`
  height: 36px;
  width: 36px;
  float: right;
  margin: -4px -9px 0 0;
  border: 3px solid transparent;
  border-radius: 50%;
  background: url(${closeB}) center center no-repeat;
  background-size: 18px;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.27, 1.64, 0.32, 0.95) 0s;

  &:hover {
    border-color: rgba(0, 0, 0, 0.8);
    transform: rotate(90deg);
  }
`;
const CloseIcon = ({ onClick }) => {
  return <StyledIcon onClick={onClick} />;
};

export default CloseIcon;

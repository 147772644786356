import { useState } from "react";
import styled from "styled-components";

import Main from "../components/Main";
import NavItems from "../components/Nav";
import { StyledNavContainer } from "../components/Nav/styles";
import { StyledAppContainer, StyledSpan, StyledNavToggler } from "../styles";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const NonAdminContainer = ({ isRegisteredUser, children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleOnClickNavToggler = () => setIsNavOpen(!isNavOpen);

  return (
    <StyledAppContainer>
      <Wrapper>
        {isRegisteredUser ? (
          <>
            <StyledNavContainer>
              <NavItems
                isNavOpen={isNavOpen}
                toggleNav={handleOnClickNavToggler}
              />
            </StyledNavContainer>
            <StyledNavToggler
              isNavOpen={isNavOpen}
              onClick={handleOnClickNavToggler}
            >
              <StyledSpan>Menu</StyledSpan>
            </StyledNavToggler>
          </>
        ) : null}
        <Main isNavOpen={isNavOpen}>{children}</Main>
      </Wrapper>
    </StyledAppContainer>
  );
};

export default NonAdminContainer;

import styled, { keyframes } from "styled-components";

const moveUp = keyframes`
  from {
    padding-top: 60%;
    opacity: 0.8;
  }
  to {
    padding-top: 0%;
    opacity: 1;
  }
`;

const moveUpTitle = keyframes`
  from {
    padding-top: 60%;
  }
  to {
    padding-top: 0%;
  }
`;

const StyledHeader = styled.div`
  height: 70px;
  background: #fff;
  padding: 0px 20px;
  display: flex;
  align-items: center;
`;

const MainContent = styled.div`
  overflow-y: overlay;
  padding: 0px 20px;
  height: 100vh;
`;

const Title = styled.h2`
  position: absolute;
  text-align: center;
  color: #fff;
  width: 100%;
  text-shadow: 1px 2px 1px #000;
  padding: 0px 10px;
  transform: translate(-10px, -50%);
  text-transform: uppercase;
  top: 50%;
  margin-top: 0;
  font-size: 26px;

  animation: ${moveUpTitle} 0.65s ease forwards;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ImageContainer = styled.img.attrs({
  className: "img-responsive",
})`
  min-height: 100px;
  width: 100%;

  animation: ${moveUp} 0.65s ease forwards;
`;

const Artist = styled.div.attrs({
  className: "col-12 col-sm-6 col-md-4 col-lg-3",
})`
  position: relative;
  padding: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: translate(0, -5px);
    -webkit-transform: translate(0, -5px);

    ${ImageContainer} {
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    }
  }
`;

const StatusIcon = styled.span`
  position: absolute;
  display: inline-block;
  top: 20px;
  right: 20px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5cb85c;

  ${({ active }) =>
    !active &&
    `
    display: none;
  `}
`;

export { StyledHeader, MainContent, Artist, ImageContainer, StatusIcon, Title };

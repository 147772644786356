import { useState, useContext } from "react";
import styled from "styled-components";

import { useMediaQuery } from "../../../hooks";
import { IFrame } from "../../../commonUI";
import { ArtistContext } from "../../../context";
import { PROTOCOL, BACKEND_HOST } from "../../../config";
import Seek from "./Seek";

const VideoContainer = styled.div`
  ${({ isMobileWindow }) =>
    isMobileWindow
      ? `
      padding: 20px;
    `
      : `
      margin-left: 60px;
      width: 450px;
      margin-top: 75px;
  `}
`;
const VideoItem = styled.div`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Videos = () => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { selectedArtist } = useContext(ArtistContext);
  const { videos } = selectedArtist;
  const [selectedIndex, setSelectedIndex] = useState(
    videos && videos.length ? 0 : null
  );
  const onClickPrev = () => {
    const val = selectedIndex - 1;
    setSelectedIndex(val === -1 ? videos.length - 1 : val);
  };
  const onClickNext = () => {
    setSelectedIndex((selectedIndex + 1) % videos.length);
  };

  if (selectedIndex === null) {
    return null;
  }

  const _renderVideos = () => {
    const attributes = `?autoplay=0&rel=0&origin=${PROTOCOL}://${BACKEND_HOST}`;

    if (isMobileWindow) {
      return (
        <IFrame
          key={videos[selectedIndex].videoId}
          aspectRatio="16:9"
          src={`https://www.youtube.com/embed/${videos[selectedIndex].videoId}${attributes}`}
        />
      );
    }

    return (
      <>
        <IFrame
          key={videos[selectedIndex].videoId}
          aspectRatio="16:9"
          src={`https://www.youtube.com/embed/${videos[selectedIndex].videoId}${attributes}`}
        />
        <div style={{ paddingTop: "10px" }}>
          <Seek type="carousel" items={videos}>
            {videos.map(
              ({ collectionId, collectionName, thumbnail }, index) => (
                <VideoItem
                  key={thumbnail}
                  onClick={() => setSelectedIndex(index)}
                >
                  <img src={thumbnail} alt={collectionName} />
                </VideoItem>
              )
            )}
          </Seek>
        </div>
      </>
    );
  };
  return (
    <>
      {isMobileWindow ? (
        <Seek
          items={videos}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
        />
      ) : null}
      <VideoContainer isMobileWindow={isMobileWindow}>
        {_renderVideos()}
      </VideoContainer>
    </>
  );
};

export default Videos;

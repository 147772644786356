import { useState, useContext } from "react";
import styled from "styled-components";

import { useMediaQuery } from "../../../hooks";
import { Album } from "../../../commonUI";
import { ArtistContext } from "../../../context";
import Seek from "./Seek";

const AlbumContainer = styled.div`
  ${({ isMobileWindow }) =>
    isMobileWindow
      ? `
      padding: 20px;
    `
      : `
      height: 350px;
      overflow: scroll;
      padding: 0px 20px;
      margin-top: 75px;
  `}
`;

const Collections = () => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { selectedArtist } = useContext(ArtistContext);
  const { collections } = selectedArtist;
  const [selectedIndex, setSelectedIndex] = useState(
    collections && collections.length ? 0 : null
  );
  const onClickPrev = () => {
    const val = selectedIndex - 1;
    setSelectedIndex(val === -1 ? collections.length - 1 : val);
  };
  const onClickNext = () => {
    setSelectedIndex((selectedIndex + 1) % collections.length);
  };

  if (selectedIndex === null) {
    return null;
  }

  const _renderAlbums = () => {
    if (isMobileWindow) {
      return <Album {...collections[selectedIndex]} />;
    }

    return collections.map((item) => (
      <Album key={item.collectionId} {...item} />
    ));
  };
  return (
    <>
      {isMobileWindow ? (
        <Seek
          items={collections}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
        />
      ) : null}
      <AlbumContainer>{_renderAlbums()}</AlbumContainer>
    </>
  );
};

export default Collections;

import { useEffect } from "react";
import styled from "styled-components";

import { useMediaQuery } from "../../../hooks";

const OtherList = styled.ul`
  list-style: none;
  width: 30%;
  border-right: 1px solid grey;
  min-height: 430px;
  padding-top: 75px;
  padding-left: 50px;
`;
const OtherItem = styled.li`
  color: ${({ isSelected }) => (isSelected ? "#1677cb" : "#4a4a4a")};
  font-size: 20px;
  padding-bottom: 15px;

  &:hover {
    color: #1677cb;
    cursor: pointer;
  }
`;

const items = [
  { key: "contact", heading: "Key Contacts", icon: "fa fa-envelope" },
  { key: "brief", heading: "Project Brief", icon: "fa fa-file-text" },
  { key: "video", heading: "Videos", icon: "fa fa-youtube-play" },
  { key: "music", heading: "Music", icon: "fa fa-play-circle" },
];

const SubHeading = ({ selectedArtist, selectedHeading, onClick }) => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");

  const finalItems = items.filter(item => {
    if (item.key === 'contact' && selectedArtist?.contact1_id) {
      return true
    }
    if (item.key === 'brief' && selectedArtist?.brief) {
      return true
    }
    if (item.key === 'video' && selectedArtist?.videos) {
      return true
    }
    if (item.key === 'music' && selectedArtist?.collections) {
      return true
    }
    return false
  })

  useEffect(() => {
    selectedArtist && finalItems.length && onClick(finalItems[0].key)
  }, [])

  if (isMobileWindow) {
    return finalItems.map(({ key, heading, icon }) => (
      <li key={key} onClick={() => onClick(key)}>
        <i className={icon} />
        {heading ? <span>{heading}</span> : null}
      </li>
    ));
  }

  return (
    <OtherList>
      {finalItems.map(({ key, heading }) => (
        <OtherItem
          key={key}
          onClick={() => onClick(key)}
          isSelected={selectedHeading === key}
        >
          {heading}
        </OtherItem>
      ))}
    </OtherList>
  );
};

export default SubHeading;

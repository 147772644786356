import { logo } from "../../assets/images";
import { Logo } from "../../commonUI";
import { StyledHeader } from "./styles";
import { OtherFilters } from "./Filter";

const Header = ({ filters, onChangeFilter }) => {
  return (
    <StyledHeader>
      <div style={{ marginRight: "1rem" }}>
        <Logo src={logo} height="50px" />
      </div>
      <OtherFilters finalFilters={filters} onChange={onChangeFilter} />
    </StyledHeader>
  );
};

export default Header;

import styled from "styled-components";

const StyledI = styled.i.attrs({
  className: "fa fa-info-circle",
})`
  color: #f5c335;
  font-size: 1.5rem;
`;

const InfoIcon = ({ onClick }) => {
  return <StyledI onClick={onClick} />;
};

export default InfoIcon;

import { useEffect } from "react";
import { useState, Children, createElement } from "react";
import styled, { keyframes, css } from "styled-components";

import { useMediaQuery } from "../hooks";

const StyledDiv = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 30px;
  color: #ccc;
  border-top: 1px solid #bebebe;
  background-color: #fff;
  z-index: 1;

  &:before {
    cursor: pointer;
  }
  &::before,
  i {
    vertical-align: middle;
  }
  &::before {
    content: "";
    width: 0;
    height: 100%;
  }
  &:hover {
    cursor: pointer;
  }
`;

const moveUp = keyframes`
from {
  bottom: -220px;
}
to {
  bottom: 50px;
}
`;
const moveDown = keyframes`
from {
  bottom: 50px;
}
to {
  bottom: -220px;
}
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  background: transparent;
  width: 100%;
  bottom: -220px;

  ${({ isShow }) =>
    isShow !== undefined &&
    css`
      animation: ${isShow ? moveUp : moveDown} 0.5s ease forwards;
    `}
`;
const Popup = styled.div`
  padding: 10px 5%;
  width: 260px;
  margin-bottom: 0;
  background: #fff;
  border: 1px solid #bebebe;
  box-shadow: 0 -1px 8px #ddd;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;

  li {
    padding: 10px 0px;
    width: 100;
    color: #616161;
    font-size: 18px;
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }

    /* &:last-child {
      i {
        color: #edb520;
      }
    } */

    &:hover {
      cursor: pointer;
      &:not(:first-child) {
        background: #ededed;
      }
      &:not(:last-child) {
        i {
          color: #000;
        }
      }
    }
  }
`;

const ActionSheet = ({ children }) => {
  const [isShowActionSheet, setIsShowActionSheet] = useState(undefined);

  const isMobileWindow = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    setIsShowActionSheet(undefined);
  }, [isMobileWindow]);

  return (
    <>
      <StyledDiv
        onClick={() => setIsShowActionSheet(isShowActionSheet ? false : true)}
      >
        <i className="fa fa-ellipsis-h" />
      </StyledDiv>
      <Container isShow={isShowActionSheet}>
        <Popup>
          <List>
            <li key="close" onClick={() => setIsShowActionSheet(false)}>
              <i className="fa fa-chevron-down" />
            </li>
            {Children.map(children, (child) =>
              createElement(
                child.type,
                {
                  ...child.props,
                  onClick: (event) => {
                    child.props.onClick(event);
                    setIsShowActionSheet(false);
                  },
                },
                child.props.children
              )
            )}
          </List>
        </Popup>
      </Container>
    </>
  );
};

export default ActionSheet;

import { useState, useEffect } from "react";

import { Modal, ModalStyles } from "../../../../commonUI";
import { useCreatePlaylist, useUpdatePlaylist } from "../../../../hooks/api";
import { Input } from "../../../../styled";

const PlaylistForm = ({
  type,
  prevTitle = "",
  id = "",
  isVisible,
  onClose,
}) => {
  const [title, setTitle] = useState(prevTitle || "");
  const { isSuccessful: isCreationSuccessful, createPlaylist } =
    useCreatePlaylist();
  const { isSuccessful: isUpdationSuccessful, updatePlaylist } =
    useUpdatePlaylist();

  useEffect(() => {
    if (isVisible) {
      setTitle(prevTitle || "");
    }
  }, [isVisible, prevTitle]);

  useEffect(() => {
    if (type === "add" && isCreationSuccessful) {
      onClose();
    }
    onClose();
  }, [isCreationSuccessful]);

  useEffect(() => {
    if (type === "edit" && isUpdationSuccessful) {
      onClose();
    }
  }, [isUpdationSuccessful]);

  const onClickCreate = () => {
    if (type === "add") {
      createPlaylist(title);
    } else {
      updatePlaylist(id, title);
    }
  };

  return (
    <Modal isVisible={isVisible}>
      <Modal.Header
        title={`${type === "add" ? "Enter " : "Edit "} playlist title`}
        onCloseModal={onClose}
      />
      <ModalStyles.Body>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter playlist title here"
          type="text"
        />
      </ModalStyles.Body>
      <ModalStyles.SubmitContainer>
        <ModalStyles.SubmitButton
          disabled={title === ""}
          kind="success"
          onClick={onClickCreate}
        >
          {type === "add" ? "Create" : "Yes"}
        </ModalStyles.SubmitButton>
        <ModalStyles.SubmitButton kind="default" onClick={onClose}>
          {type === "add" ? "Cancel" : "No"}
        </ModalStyles.SubmitButton>
      </ModalStyles.SubmitContainer>
    </Modal>
  );
};

export default PlaylistForm;

import styled from "styled-components";
import Slider from "react-slick";

const Top = styled.div`
  height: 50px;
  background-color: ${({ isTransparent }) =>
    isTransparent ? "transparent" : "#e7e7e7"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  color: #fff;
`;
const I = styled.i`
  width: 30px;
  padding: 10px;
  cursor: pointer;
  color: #646464;

  &:hover {
    font-size: 20px;
  }
`;

const Left = ({ onClick }) => (
  <I onClick={onClick} className="fa fa-chevron-left" />
);
const Right = ({ onClick }) => (
  <I onClick={onClick} className="fa fa-chevron-right" />
);

function Arrow(props) {
  return props.type === "next" ? (
    <Right onClick={props.onClick} />
  ) : (
    <Left onClick={props.onClick} />
  );
}

const Seek = ({
  type = "top",
  isTransparent = false,
  items,
  onClickPrev,
  onClickNext,
  children,
}) => {
  if (type === "top") {
    return (
      <Top isTransparent={isTransparent}>
        {items && items.length > 2 ? (
          <Left onClick={() => onClickPrev && onClickPrev()} />
        ) : null}
        {items && items.length > 2 ? (
          <Right onClick={() => onClickNext && onClickNext()} />
        ) : null}
      </Top>
    );
  }

  if (type === "carousel") {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <Arrow type="next" />,
      prevArrow: <Arrow type="prev" />,
    };
    return <Slider {...settings}>{children}</Slider>;
  }
  return null;
};

export default Seek;

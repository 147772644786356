import { useState } from "react";
import styled from "styled-components";

import { ActionSheet } from "../../../commonUI";
import { GoBackIcon } from "./styles";
import SubHeading from "./SubHeadings";
import Contacts from "./Contacts";
import ProjectBrief from "./ProjectBrief";
import Video from "./Videos";
import Collections from "./Collections";

const DetailContainerMobileDevice = styled.div`
  position: relative;
  background-color: #fff;
  @media (min-width: 768px) {
    display: none;
  }
`;
const ArtistImage = styled.div`
  position: relative;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 180px;
  padding-top: 20px;
  text-align: center;

  @media (min-width: 768px) {
    display: none;
  }
`;
const ArtistTitle = styled.span`
  text-shadow: 1px 1px 1px #000;
  color: #fff;
  font-size: 35px;
  position: relative;
  bottom: -30px;

  &:after {
    position: absolute;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #1bb40b;
    line-height: 50;
    top: 10px;
    right: -20px;
  }
`;

const DetailMobileDevice = ({ selectedArtist, onClickBack }) => {
  const [subSection, setSubSection] = useState("");

  const _renderSubSection = () => {
    switch (subSection) {
      case "contact":
        return <Contacts />;
      case "brief":
        return <ProjectBrief />;
      case "video":
        return <Video />;
      case "music":
        return <Collections />;
      default:
        return null;
    }
  };

  return (
    <>
      <ArtistImage imgUrl={selectedArtist.thumbnail}>
        <GoBackIcon onClick={onClickBack}>
          <i className="fa fa-angle-left" />
        </GoBackIcon>
        <ArtistTitle isRecentUpdate={selectedArtist.Status !== "Active"}>
          {selectedArtist.Artist || ""}
        </ArtistTitle>
      </ArtistImage>
      <DetailContainerMobileDevice>
        {_renderSubSection()}
      </DetailContainerMobileDevice>
      <ActionSheet>
        <SubHeading 
          selectedArtist={selectedArtist} 
          onClick={setSubSection}
        />
      </ActionSheet>
    </>
  );
};

export default DetailMobileDevice;

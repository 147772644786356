import styled from "styled-components";

const Select = styled.select`
  height: 40px;
  padding: 6px 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  width: 100%;

  &:focus {
    border-color: #66afe9;
    outline: 0;
  }

  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out 0.15s;
  -webkit-box-shadow: ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

export default Select;

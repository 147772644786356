import styled, { css } from "styled-components";

const StyledNavContainer = styled.div.attrs(() => ({
  className: "container-fluid",
}))`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f1b821;
  color: #000;
  padding: 1rem 1rem;

  @media (min-width: 576px) {
    padding: 4rem 1rem;
  }
`;

const BaseAnimation = css`
  transition: transform 1.2s, opacity 1.2s;
  -webkit-transition: transform 1.2s, opacity 1.2s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

  ${({ isNavOpen }) =>
    isNavOpen
      ? `
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0);`
      : `
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);`}
`;

const StyledNavItems = styled.div.attrs(() => ({
  className: "row justify-content-center",
}))`
  ${BaseAnimation}
`;

const StyledNavItem = styled.div.attrs({
  className: "col-12 col-sm-6 col-md-4",
})`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
  :hover {
    cursor: pointer;
  }

  @media (min-width: 576px) {
    text-align: center;
    align-items: center;
    margin-bottom: 4rem;
  }
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;

  @media (min-width: 576px) {
    font-size: 1rem;
  }
  ${({ faded }) =>
    faded
      ? `
      color: #4f4f64;
      font-size: 0.8rem !important;
      font-weight: 700;
    `
      : `
      font-weight: 700;
      :hover:before {
        transform: scale3d(1, 1, 1);
      }
      @media (min-width: 576px) {
        :before {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: 30px;
          height: 2px;
          margin: 5px 0 0 -15px;
          background: #000;
          transition: transform 0.3s;
          transform: scale3d(0, 1, 1);
        }
      }
    `}

  letter-spacing: 1px;
  width: 80%;

  :hover {
    color: #000;
  }
`;

const StyledSocialContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 0%;
  opacity: 0;

  ${BaseAnimation}

  ${({ isNavOpen }) =>
    isNavOpen &&
    `
    transition-delay: .15s;
    opacity: 1;
  `}

  @media (min-width: 576px) {
    justify-content: center;
  }
`;

const StyledSocialItem = styled.a`
  margin-right: 20px;
  color: #4f4f64;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 1px;
  ${({ hideUnderline }) => hideUnderline && `text-decoration: none;`}

  :hover {
    color: #000;
    cursor: pointer;
  }
`;

const StyledSocialIcon = styled(StyledSocialItem).attrs(({ icon }) => ({
  className: icon,
}))`
  color: #4f4f64;
  font-size: 1.2rem;
`;

export {
  StyledNavContainer,
  StyledNavItems,
  StyledNavItem,
  StyledLink,
  StyledSocialContainer,
  StyledSocialItem,
  StyledSocialIcon,
};

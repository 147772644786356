import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -200px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ settings }) => (settings && settings.accent_color) || "#fff"};
`;

const StyledSongs = styled.div`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: clip;
`;
const StyledSongContainer = styled.div`
  padding: 5px 0px;
  border: ${({ isOver }) => (isOver ? "1px dotted #f5c335" : "none")};
`;
const StyledSong = styled.div`
  display: flex;
  align-items: center;
  word-wrap: break-word;

  i {
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 20px;
    color: ${({ settings }) => (settings && settings.accent_color) || "#fff"};

    &:hover {
      cursor: pointer;
    }
  }
  span {
    font-size: 14px;
    color: ${({ settings }) => (settings && settings.font_color) || "#fff"};
  }
`;
const StyledSongTitleContainer = styled.div`
  padding: 0px 0px 0px 5px;
  margin-right: 15px;
  margin-left: 5px;
  flex-grow: 1;
  max-height: 20px;
  padding-right: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ProgressBarContainer = styled.div`
  position: relative;
  margin-left: 60px;
  margin-right: 40px;
`;
const DragIcon = styled.svg.attrs({
  xmlns: "http://www.w3.org/2000/svg",
  preserveAspectRatio: "xMidYMid",
  width: "23",
  height: "18",
  viewBox: "0 0 12 13",
})`
  margin-right: 10px;

  path {
    fill: ${({ settings }) => (settings && settings.accent_color) || "#fff"};
  }

  &:hover {
    cursor: all-scroll;
  }
  &:focus {
    outline: none;
  }
`;
const Duration = styled.span`
  position: absolute;
  bottom: 5px;
  right: 0px;
  color: ${({ settings }) => (settings && settings.font_color) || "#fff"};
`;

export {
  Container,
  StyledSongs,
  StyledSongContainer,
  StyledSong,
  ProgressBarContainer,
  StyledSongTitleContainer,
  DragIcon,
  Duration,
};

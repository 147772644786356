const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const PROTOCOL = process.env.REACT_APP_PROTOCOL;
const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
const SERVE_HOST = process.env.REACT_APP_SERVE_HOST;
const SERVE_PORT = process.env.REACT_APP_SERVE_PORT;
const SERVER_CERTIFICATE_KEY_FILE_PATH =
  process.env.REACT_APP_SERVER_CERTIFICATE_KEY_FILE_PATH;
const SERVER_CERTIFICATE_CERT_FILE_PATH =
  process.env.REACT_APP_SERVER_CERTIFICATE_CERT_FILE_PATH;

const PROD_ENV = "production";
const STAGE_ENV = "staging";
const DEV_ENV = "development";

const API_BASE = `${PROTOCOL}://${BACKEND_HOST}`;

exports.NODE_ENV = NODE_ENV;
exports.PROTOCOL = PROTOCOL;
exports.BACKEND_HOST = BACKEND_HOST;
exports.SERVE_HOST = SERVE_HOST;
exports.SERVE_PORT = SERVE_PORT;
exports.API_BASE = API_BASE;
exports.PROD_ENV = PROD_ENV;
exports.STAGE_ENV = STAGE_ENV;
exports.DEV_ENV = DEV_ENV;
exports.SERVER_CERTIFICATE_KEY_FILE_PATH = SERVER_CERTIFICATE_KEY_FILE_PATH;
exports.SERVER_CERTIFICATE_CERT_FILE_PATH = SERVER_CERTIFICATE_CERT_FILE_PATH;

import { useState, useEffect } from "react";

import { Modal, ModalStyles } from "../../../commonUI";
import { isValidURL } from "../../../helper/utility";
import { postVideoAPI } from "../../../api/admin.http.service";
import { useFetchAPI } from "../../../hooks";
import { Input, Select } from "../../../styled";

const VideoAdd = ({ isVisible, onCloseModal, projects }) => {
  const [projectId, setProjectId] = useState("");
  const [url, setUrl] = useState("");
  const [{ isLoading, response }, fetch] = useFetchAPI();

  useEffect(() => {
    if (isVisible) {
      setProjectId("");
      setUrl("");
    }
  }, [isVisible]);

  useEffect(() => {
    if (isLoading === false && response.status === "ok") {
      onCloseModal(true);
    }
  }, [isLoading]);

  const onClickCreate = () => {
    fetch({
      api: postVideoAPI,
      payload: {
        body: {
          project_id: projectId,
          url,
        },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  const isURLInvalid = !isValidURL(url);
  const isInvalidFields = !projectId || isURLInvalid;

  return (
    <Modal isVisible={isVisible} backgroundColor="rgba(240, 243, 244, 0.8)">
      <Modal.Header
        icon="fa fa-envelope"
        title="Add video"
        onCloseModal={onCloseModal}
      />
      <Modal.Form>
        <Modal.FormGroup title="Email">
          <Select
            value={projectId || ""}
            onChange={(e) => setProjectId(e.target.value)}
            // style={itemSeparator}
          >
            <option key="select_project" value="">
              Select Project
            </option>
            {projects?.map(({ id, Artist }) => (
              <option key={id} value={id}>
                {Artist || ""}
              </option>
            ))}
          </Select>
        </Modal.FormGroup>
        <Modal.FormGroup title="Password">
          <Input
            value={url}
            placeholder="http://"
            isInvalid={url && isURLInvalid}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Modal.FormGroup>
      </Modal.Form>
      <ModalStyles.SubmitContainer>
        <ModalStyles.SubmitButton
          disabled={isInvalidFields}
          kind="success"
          onClick={onClickCreate}
        >
          Save
        </ModalStyles.SubmitButton>
        <ModalStyles.SubmitButton kind="default" onClick={onCloseModal}>
          Cancel
        </ModalStyles.SubmitButton>
      </ModalStyles.SubmitContainer>
    </Modal>
  );
};

export default VideoAdd;

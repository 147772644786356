import { useState, useEffect } from "react";

import { getUserInfoAPI } from "../../api/user.http.service";
import useFetchAPI from "../useFetchAPI";

const useUserInfo = (shouldFetch) => {
  const [userInfo, setUserInfo] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  useEffect(() => {
    shouldFetch &&
      fetch({
        api: getUserInfoAPI,
        payload: {},
      });
  }, [shouldFetch]);

  useEffect(() => {
    if (isLoading === false && response?.status === "ok" && response?.user) {
      setUserInfo(response.user);
    }
  }, [isLoading]);

  return userInfo;
};

export default useUserInfo;

import { useState, useEffect, useContext } from "react";

import { getUserSongAPI } from "../../api/user.http.service";
import PlaylistContext from "../../components/Playlist/PlaylistContext";
import useFetchAPI from "../useFetchAPI";

const useUserSongs = () => {
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const { selectedPlaylist } = useContext(PlaylistContext);
  const [userSongs, setUserSongs] = useState([]);
  const playlistId = selectedPlaylist && selectedPlaylist.data.id;

  useEffect(() => {
    playlistId &&
      fetch({
        api: getUserSongAPI,
        payload: {
          params: { playlist_id: playlistId },
        },
      });
  }, [playlistId]);

  useEffect(() => {
    if (isLoading === false && response) {
      setUserSongs(response);
    }
  }, [isLoading]);

  return userSongs;
};

export default useUserSongs;

import styled from "styled-components";

import { Select } from "../../../styled";

const StyledDropdownItemsContainer = styled.ul`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  position: absolute;
  top: 38px;
  left: 0px;
  right: 0px;
  width: 100px;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: #ccc !important;
  max-height: 200px;
  width: 300px;
  padding: 0px;
  margin-left: -1px;
  overflow-y: auto;
  list-style: none;
  z-index: 1;
`;
const StyledDropdown = styled.div`
  position: relative;
  padding: 10px 1rem;
  font-size: 14px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border-color: #ccc #ccc transparent #ccc;
    border-radius: 4px 4px 0 0;
  }
`;
const StyledDropdownItem = styled.li`
  color: #444;
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
  vertical-align: middle;
  color: #565656;
  font-weight: 600;

  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }

  :first-child {
    text-transform: capitalize;
  }
`;
const StyledDropdownHeading = styled.span`
  color: #565656;
  font-weight: 600;
`;

const StyledDropdownIcon = styled.i.attrs({
  className: "fa fa-angle-down",
})`
  margin-left: 10px;
`;

const SuggestionBoxContainer = styled.span`
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: ${({ size }) => (size === "full" ? "100%" : "300px")};
  height: 40px;
  padding: 7px 20px;
`;
const SuggestionInput = styled.input.attrs({
  type: "text",
})`
  width: 100%;
  border: none;
  font-size: 16px;

  &:focus {
    outline: none;
  }
`;
const SearchIcon = styled.i.attrs({
  className: "fa fa-search",
})`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
`;
const Suggestion = styled(StyledDropdownItem)`
  color: ${({ isTitle }) => (isTitle ? "#9a99ab" : "#aaa")};
  text-transform: ${({ isTitle }) => (isTitle ? "uppercase" : "capitalize")};

  ${({ isTitle }) => isTitle && `background-color: #f9fafc`};

  &:hover {
    background-color: ${({ isTitle }) => (isTitle ? "#f9fafc" : "#428bca")};
    ${({ isTitle }) => !isTitle && `color: #fff`};
    cursor: ${({ isTitle }) => (isTitle ? "default" : "pointer")};
  }
`;
const CrossIcon = styled.i.attrs({
  className: "fa fa-times",
})`
  position: absolute;
  top: 10px;
  left: 5px;
  color: #565656;

  &:hover {
    cursor: pointer;
  }
`;
const SelectDropdown = styled(Select)`
  margin-top: 10px;
`;

export {
  StyledDropdownItem,
  StyledDropdownItemsContainer,
  StyledDropdown,
  StyledDropdownHeading,
  StyledDropdownIcon,
  SuggestionBoxContainer,
  SuggestionInput,
  Suggestion,
  SearchIcon,
  CrossIcon,
  SelectDropdown,
};

import { connect } from "react-redux";

import { StyledNavItems } from "./styles";
import NavItem from "./NavItem";
import Social from "./Social";
import { authAction } from "../../redux/actions";
import { ROUTE } from "../../helper/constants";

const NavItems = ({ isNavOpen, toggleNav, token, logout }) => {
  return (
    <>
      <StyledNavItems isNavOpen={isNavOpen}>
        {token ? (
          <>
            <NavItem href={ROUTE.projects} text="leads" />
            <NavItem href={ROUTE.playlist} text="songs pitch system" />
            <NavItem
              href="https://myhitonline.com/community"
              text="community"
            />
            <NavItem
              href="https://myhitonline.com/resources"
              text="resources"
            />
            <NavItem href={ROUTE.backend.landing} text="admin panel" />
            <NavItem
              text="logout"
              faded
              onClick={() => {
                logout();
                toggleNav();
              }}
            />
          </>
        ) : (
          <NavItem href={`/${ROUTE.userLogin}`} text="login" faded />
        )}
      </StyledNavItems>
      <Social isNavOpen={isNavOpen} />
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.app.auth.token,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({ type: authAction.LOGOUT_SUCCESS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItems);

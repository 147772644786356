import { useEffect } from "react";

import { putUserAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useUpdateUser = (callback) => {
  const [{ isLoading, response }, fetch] = useFetchAPI();

  const updateUser = (payload) => {
    fetch({
      api: putUserAPI,
      payload,
    });
  };

  useEffect(() => {
    if (isLoading === false && response) {
      callback?.(response);
    }
  }, [isLoading]);

  return updateUser;
};

export default useUpdateUser;

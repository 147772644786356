import { useEffect, useContext } from "react";

import { Modal } from "../../../../commonUI";
import { useMediaQuery } from "../../../../hooks";
import { formatDate } from "../../../../helper/utility";
import { Select } from "../../../../styled";
import PlaylistContext from "../../PlaylistContext";
import {
  Icon,
  StyledActionsContainer,
  StyledFooter,
  OtherDeviceActionsContainer,
} from "./styles";
import CreatePlaylist from "./CreatePlaylist";
import EditPlaylist from "./EditPlaylist";
import RemovePlaylist from "./RemovePlaylist";

const getPlaylistIndex = (list, id) => {
  let idx = null;

  list.some((item, index) => {
    if (item.id === parseInt(id, 10)) {
      idx = index;
      return true;
    }
    return false;
  });

  return idx;
};

const ActionsContainer = ({ onShareLink }) => {
  const { userPlaylist, selectedPlaylist, onChangePlaylist } =
    useContext(PlaylistContext);

  useEffect(() => {
    if (userPlaylist && userPlaylist.length) {
      if (selectedPlaylist) {
        const idx =
          getPlaylistIndex(userPlaylist, selectedPlaylist.data.id) || 0;
        onChangePlaylist(idx, userPlaylist[idx]);
        return;
      }
      onChangePlaylist(0, userPlaylist[0]);
    }
  }, [userPlaylist]);

  const handleOnChangePlaylist = (e) => {
    const idx = getPlaylistIndex(userPlaylist, e.target.value) || 0;
    onChangePlaylist(idx, userPlaylist[idx]);
  };

  const value =
    (selectedPlaylist &&
      userPlaylist[selectedPlaylist.index] &&
      userPlaylist[selectedPlaylist.index].id) ||
    "";
  const createdAt =
    selectedPlaylist &&
    userPlaylist[selectedPlaylist.index] &&
    userPlaylist[selectedPlaylist.index].created_at;

  return (
    <>
      <StyledActionsContainer>
        <CreatePlaylist />
        <div style={{ width: "270px", flexGrow: 1 }}>
          <Select value={value} onChange={handleOnChangePlaylist}>
            {!userPlaylist || userPlaylist.length === 0 ? (
              <option value="">No playlist created yet</option>
            ) : (
              userPlaylist.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))
            )}
          </Select>
        </div>
        <Icon onClick={onShareLink} bg="blue">
          <i className="fa fa-link" />
        </Icon>
        <RemovePlaylist />
      </StyledActionsContainer>
      <StyledFooter>
        {createdAt ? (
          <span style={{ color: "#000", fontSize: "12px", padding: "5px" }}>
            {`Created at: ${formatDate(createdAt, "d/M/yy H:mb")}`}
          </span>
        ) : null}
        <EditPlaylist />
      </StyledFooter>
    </>
  );
};

const PlaylistActions = ({ isVisible, onClose, onShareLink }) => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");

  if (isMobileWindow) {
    return (
      <Modal isVisible={isVisible}>
        <Modal.Header
          icon="fa fa-info-circle"
          title="Song info"
          onCloseModal={onClose}
        />
        <ActionsContainer onShareLink={onShareLink} />
      </Modal>
    );
  }
  return isVisible ? (
    <OtherDeviceActionsContainer>
      <ActionsContainer onShareLink={onShareLink} />
    </OtherDeviceActionsContainer>
  ) : null;
};

export default PlaylistActions;

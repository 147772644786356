import { useState, useRef, useCallback } from "react";

import { InfoIcon } from "../../commonUI";
import { useSearchFilterData, useProjectSearch } from "../../hooks/api";
import { ArtistContext } from "../../context";
import { MobileScreenFilters } from "./Filter";
import Header from "./Header";
import ArtistCount from "./ArtistCount";
import Detail from "./Detail";
import {
  MainContent,
  Artist as StyledArtist,
  ImageContainer,
  StatusIcon,
  Title,
} from "./styles";

const Projects = () => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const { response: filterDataResponse } = useSearchFilterData();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [filters, setFilters] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const { isLoading, error, count, projects, hasMore } = useProjectSearch(
    filters,
    pageNumber
  );

  const observer = useRef();
  const lastProjectElementRef = useCallback(
    (node) => {
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore]
  );

  const _renderArtists = () => {
    if (projects === undefined) {
      return null;
    }
    if (projects.length) {
      return projects.map((artist, index) => {
        const { id, Artist, thumbnail, thumbnail_medium, thumbnail_high, Status } = artist;
        const onClickArtist = () => {
          setIsShowDetail(true);
          setSelectedArtist(artist);
        };

        return (
          <StyledArtist
            key={id}
            {...(projects.length === index + 1 && {
              ref: lastProjectElementRef,
            })}
            onClick={onClickArtist}
          >
            <ImageContainer src={thumbnail_high || thumbnail_medium || thumbnail} />
            <StatusIcon active={Status === "Active"} />
            <Title>{Artist}</Title>
          </StyledArtist>
        );
      });
    }
    return <p>No artist found</p>;
  };
  const onChangeFilter = (updatedFilter) => {
    setFilters(updatedFilter);
    setPageNumber(0);
  };
  const onClickReset = () => onChangeFilter({});

  return (
    <div className="container-fluid">
      <div className="row p-0">
        <ArtistContext.Provider
          value={{
            setIsShowDetail,
            selectedArtist,
            updateSelectedArtist: setSelectedArtist,
            filterData: filterDataResponse,
          }}
        >
          <Header filters={filters} onChangeFilter={onChangeFilter} />
          <MainContent>
            <MobileScreenFilters
              finalFilters={filters}
              onChange={onChangeFilter}
            />
            <div className="container-fluid">
              <div className="row align-items-center">
                <ArtistCount count={count} onReset={onClickReset} />
                <div className="col-6 text-end">
                  <InfoIcon />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "100px" }}>
              {_renderArtists()}
            </div>
          </MainContent>
          <Detail isVisible={isShowDetail}></Detail>
        </ArtistContext.Provider>
      </div>
    </div>
  );
};

export default Projects;

import { useState, useEffect } from "react";
import compareAsc from "date-fns/compareAsc";
import parseISO from "date-fns/parseISO";

import { getUserPlaylistAPI } from "../../api/user.http.service";
import useFetchAPI from "../useFetchAPI";

const useUserPlaylist = (autoFetch = true) => {
  const [userPlaylist, setUserPlaylist] = useState(undefined);
  const [{ isLoading, response }, fetch] = useFetchAPI();

  const fetchPlaylist = () => {
    fetch({
      api: getUserPlaylistAPI,
      payload: {
        params: {},
      },
    });
  };

  useEffect(() => {
    autoFetch && fetchPlaylist();
  }, []);

  useEffect(() => {
    if (isLoading === false && response) {
      setUserPlaylist(
        [...response].sort((a, b) =>
          compareAsc(parseISO(b.created_at), parseISO(a.created_at))
        )
      );
    }
  }, [isLoading]);

  return { userPlaylist, fetchPlaylist };
};

export default useUserPlaylist;

import { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Modal, ModalStyles, ColorPicker, Switch } from "../../../commonUI";
import { ImagePicker, Input, Select } from "../../../styled";
import { useFetchAPI } from "../../../hooks";
import { DEFAULT_PLAYLIST_SETTINGS } from "../../../helper/constants";
import {
  putUserOptionAPI,
  putUserOptionUploadImageAPI,
} from "../../../api/user.http.service";
import PlaylistContext from "../PlaylistContext";

const imageRepeatOptions = ["Repeat", "Repeat-X", "Repeat-Y", "No-repeat"];
const imagePositionOptions = [
  "top left",
  "top center",
  "center",
  "top right",
  "center left",
  "center right",
  "bottom left",
  "bottom center",
  "bottom right",
];
const ButtonContainer = styled.div`
  width: 50%;
  text-align: right;
  padding-right: 15px;
`;
const ResetButton = styled.button.attrs({
  type: "button",
  className: "btn btn-danger",
})`
  font-size: 16px;
  font-weight: 700;
`;

const ResetConsent = ({
  isShowResetPermission,
  closePermissionModal,
  onClickReset,
}) => {
  return (
    <Modal isVisible={isShowResetPermission}>
      <Modal.Header title="Reset options" onCloseModal={closePermissionModal} />
      <ModalStyles.Body>Are you sure?</ModalStyles.Body>
      <ModalStyles.SubmitContainer>
        <ModalStyles.SubmitButton kind="success" onClick={onClickReset}>
          Yes
        </ModalStyles.SubmitButton>
        <ModalStyles.SubmitButton kind="default" onClick={closePermissionModal}>
          No
        </ModalStyles.SubmitButton>
      </ModalStyles.SubmitContainer>
    </Modal>
  );
};

const Customize = ({ isVisible, settings, onClose }) => {
  const [{ isLoading, response }, fetchUserOption] = useFetchAPI();
  const [
    { isLoading: isImageUploadLoading, response: imageUploadResponse },
    fetchUserOptionUploadImage,
  ] = useFetchAPI();
  const { updateSettings, isSongDragable, onAlterSongDragable } =
    useContext(PlaylistContext);
  const [isShowResetPermission, setIsShowResetPermission] = useState(false);
  const opacity = (settings && settings.overlay_opacity) || "40";
  const [opacityInput, setOpacityInput] = useState(opacity);

  useEffect(() => {
    if (isLoading === false && response && response.status === "ok") {
      updateSettings(response.option[0]);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isImageUploadLoading === false && imageUploadResponse) {
      // check response to update settings
    }
  }, [isImageUploadLoading]);

  const fetch = (body) => {
    fetchUserOption({
      api: putUserOptionAPI,
      payload: {
        body,
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };
  const handleOnChangeImage = (e) => {
    fetchUserOptionUploadImage({
      api: putUserOptionUploadImageAPI,
      payload: {
        body: {
          image: e.target.files[0],
        },
      },
    });
  };

  const handleOnChange = (type, value) => {
    fetch({
      ...settings,
      [type]: value,
    });
  };
  const closePermissionModal = () => setIsShowResetPermission(false);
  const handleOnClickReset = () => {
    fetch({
      ...settings,
      ...DEFAULT_PLAYLIST_SETTINGS,
    });
    onAlterSongDragable(true);
    closePermissionModal();
  };
  const handleOnKeyPressOpacity = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = Number(keyCode);

    if (keyValue !== 8 && keyValue !== 46 && (keyValue < 48 || keyValue > 57)) {
      event.preventDefault();
      return;
    }
  };
  const handleOnChangeOpacity = (e) => {
    let value = e.target.value ? Number(e.target.value) : 0;

    if (value > 100) {
      value = 100;
    }
    setOpacityInput(value);

    if (value !== Number(opacity || 0)) {
      handleOnChange("overlay_opacity", value);
    }
  };

  return (
    <>
      <Modal isVisible={isVisible}>
        <Modal.Header
          icon="fa fa-cogs"
          title="Playlist settings"
          onCloseModal={onClose}
        />
        <Modal.Form>
          <Modal.FormGroup title="Select image">
            <ImagePicker onChange={handleOnChangeImage} />
          </Modal.FormGroup>
          <Modal.FormGroup title="Background color">
            <ColorPicker
              selectedColor={settings && settings.background_color}
              onChange={(value) => handleOnChange("background_color", value)}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Image repeat">
            <Select
              value={(settings && settings.image_repeat) || "No-repeat"}
              onChange={(e) => handleOnChange("image_repeat", e.target.value)}
            >
              {imageRepeatOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </Modal.FormGroup>
          <Modal.FormGroup title="Image attachment">
            <Select
              value={(settings && settings.image_attachment) || "Fixed"}
              onChange={(e) =>
                handleOnChange("image_attachment", e.target.value)
              }
            >
              <option value="Fixed">Fixed</option>
            </Select>
          </Modal.FormGroup>
          <Modal.FormGroup title="Image position">
            <Select
              value={(settings && settings.image_position) || "center"}
              onChange={(e) => handleOnChange("image_position", e.target.value)}
              style={{ textTransform: "capitalize" }}
            >
              {imagePositionOptions.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </Modal.FormGroup>
          <Modal.FormGroup title="Centre scale">
            <Switch
              value={(settings && settings.center_scale) || true}
              onChange={(isChecked) =>
                handleOnChange("center_scale", isChecked)
              }
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Overlay color">
            <ColorPicker
              selectedColor={settings && settings.overlay_color}
              onChange={(value) => handleOnChange("overlay_color", value)}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Overlay opacity (%)">
            <Input
              value={opacityInput}
              maxLength={3}
              onKeyPress={handleOnKeyPressOpacity}
              onChange={handleOnChangeOpacity}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Set as default">
            <Switch />
          </Modal.FormGroup>
          <Modal.FormGroup title="Font color">
            <ColorPicker
              selectedColor={settings && settings.font_color}
              onChange={(value) => handleOnChange("font_color", value)}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Icon color">
            <ColorPicker
              selectedColor={settings && settings.accent_color}
              onChange={(value) => handleOnChange("accent_color", value)}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Icon color (on hover)">
            <ColorPicker
              selectedColor={settings && settings.accent_color_strong}
              onChange={(value) => handleOnChange("accent_color_strong", value)}
            />
          </Modal.FormGroup>
          <Modal.FormGroup title="Drag & drop songs">
            <Switch
              value={isSongDragable}
              onChange={() => onAlterSongDragable()}
            />
          </Modal.FormGroup>
          <Modal.FormGroup>
            <ButtonContainer>
              <ResetButton onClick={() => setIsShowResetPermission(true)}>
                Reset all settings
              </ResetButton>
            </ButtonContainer>
          </Modal.FormGroup>
        </Modal.Form>
      </Modal>
      <ResetConsent
        isShowResetPermission={isShowResetPermission}
        closePermissionModal={() => setIsShowResetPermission(false)}
        onClickReset={handleOnClickReset}
      />
    </>
  );
};

export default Customize;

import { useState, useEffect } from "react";
import omit from "lodash/omit";

import { Table, TableButton, PageHeader } from "../../../commonUI";
import { CardToolbox, Input, Select } from "../../../styled";
import { useAudits } from "../../../hooks/api";
import { useMediaQuery } from "../../../hooks";

const columns = [
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Ip",
    dataIndex: "ip",
    key: "ip",
  },
  {
    title: "Path",
    dataIndex: "virtual_path",
    key: "virtual_path",
  },
  {
    title: "Audit Type",
    dataIndex: "audit_type",
    key: "audit_type",
  },
];

const auditTypes = [
  { value: "Login", label: "Login" },
  { value: "Logout", label: "Logout" },
  { value: "PaymentInfo", label: "PaymentInfo" },
  { value: "Registration", label: "Registration" },
];

const Audits = () => {
  const { audits, fetchAudits, total } = useAudits();
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [user, setUser] = useState("");

  const isMobileWindow = useMediaQuery("(max-width: 576px)");

  const getAudits = (params) => {
    fetchAudits(params);
  };

  useEffect(() => {
    getAudits({ page: currentPage, ...filters });
  }, [currentPage]);

  const onClearAllFilters = () => {
    setFilters({});
    setUser("");
    getAudits({ page: 1 });
  };

  const handleOnChange = (type, value) => {
    let finalFilters;

    if (value === "") {
      finalFilters = omit(filters, type);
      setFilters(finalFilters);
    } else {
      finalFilters = { ...filters, [type]: value };
      setFilters(finalFilters);
    }
    getAudits({ page: currentPage, ...finalFilters });
  };

  const auditsTableData = [];

  audits?.map((audit) => {
    const { id, createdAt, name, email, ip, virtual_path, audit_type } = audit;

    return auditsTableData.push({
      key: id,
      createdAt,
      name,
      email,
      ip,
      virtual_path,
      audit_type,
    });
  });

  const onChangePagination = ({ current }) => {
    setCurrentPage(current);
  };

  if (!audits) {
    return null;
  }

  const itemSeparator = isMobileWindow
    ? { marginBottom: "10px" }
    : { marginRight: "10px", width: "50%" };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title="Audits"
          subTitle={
            <>
              <Select
                value={filters.audit_type || ""}
                onChange={(e) => handleOnChange("audit_type", e.target.value)}
                style={itemSeparator}
              >
                <option key="select_type" value="">
                  Select type
                </option>
                {auditTypes.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <Input
                value={user}
                onChange={(e) => setUser(e.target.value)}
                onKeyPress={(e) =>
                  e.which === 13 && handleOnChange("user", e.target.value)
                }
                style={itemSeparator}
                placeholder="Search"
                type="text"
              />
              <TableButton
                className="btn-add_new"
                size="default"
                type="primary"
                onClick={onClearAllFilters}
              >
                Clear
              </TableButton>
            </>
          }
          buttons={[]}
        />
      </CardToolbox>
      <Table
        dataSource={auditsTableData}
        columns={columns}
        pagination={{
          defaultPageSize: 20,
          total,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          pageSizeOptions: [],
        }}
        onChange={onChangePagination}
      />
    </>
  );
};

export default Audits;

import { useContext } from "react";
import styled, { keyframes } from "styled-components";

import { ArtistContext } from "../../../context";

const animate = keyframes`
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const Brief = styled.p`
  padding: 20px;
  font-size: 18px;
  animation: ${animate} 1.5s ease 1s forwards;
`;

const ProjectBrief = () => {
  const { selectedArtist } = useContext(ArtistContext);

  return <Brief>{selectedArtist.ProjectNotes || "NA"}</Brief>;
};

export default ProjectBrief;

import styled from "styled-components";

const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: ${({ aspectRatio }) => {
    switch (aspectRatio) {
      case "16:9":
        return "56.25%"; // 9/16
      case "4:3":
        return "75%";
      case "3:2":
        return "66.66%";
      case "8:5":
        return "62.5%";
      default:
        return "50%";
    }
  }};
`;
const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const IFrame = ({ aspectRatio = "4:3", src }) => {
  return (
    <IframeContainer aspectRatio={aspectRatio}>
      <Frame src={src} frameborder="0" />
    </IframeContainer>
  );
};

export default IFrame;

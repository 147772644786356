import { useState, useEffect, useRef } from "react";

import { getAuditAPI } from "../../api/admin.http.service";
import useFetchAPI from "../useFetchAPI";

const useAudits = () => {
  const [audits, setAudtits] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const page = useRef();

  const fetchAudits = (params) => {
    page.current = params.page;

    fetch({
      api: getAuditAPI,
      payload: { params },
    });
  };

  useEffect(() => {
    if (isLoading === false) {
      if (response?.status === "ok" && response?.audits) {
        if (page.current === 1) {
          setTotal(response?.data?.objCount || 0);
          setPageCount(response?.data?.pageCount || 0);
        }
        setAudtits(response.audits);
      } else {
        setAudtits([]);
      }
    }
  }, [isLoading]);

  return { audits, fetchAudits, total, pageCount };
};

export default useAudits;

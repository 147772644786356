import styled from "styled-components";

const StyledLoginLogo = styled.img.attrs({
  className: "img-responsive",
})`
  height: ${({ height }) => height || "72px"};
  width: auto;
  vertical-align: bottom;
`;

const Logo = (props) => {
  return <StyledLoginLogo {...props} />;
};

export default Logo;

import { GET, POST, PUT } from "./api.http.service";

export const getUserAPI = (payload) => GET("user")(payload);
export const getAuditAPI = (payload) => GET("audit")(payload);
export const getAlbumAPI = (payload) => GET("album")(payload);
export const getVideoAPI = (payload) => GET("video")(payload);
export const getProjectAPI = (payload) => GET("project")(payload);

export const putUserAPI = (payload) =>
  PUT(`user/${payload.subpath.id}`)(payload);
export const postUserCreateNewAPI = (payload) =>
  POST("user/create_new")(payload);

export const putAlbumAPI = (payload) =>
  PUT(`album/${payload.subpath.id}`)(payload);

export const postVideoAPI = (payload) => POST("video/video_add")(payload); // project_id, url
export const putVideoAPI = (payload) =>
  PUT(`video/${payload.subpath.id}`)(payload);

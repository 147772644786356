import { useState, useEffect, useContext } from "react";

import { postUserPlaylistShareKeyAPI } from "../../api/user.http.service";
import PlaylistContext from "../../components/Playlist/PlaylistContext";
import useFetchAPI from "../useFetchAPI";

const useCreatePlaylistShareKey = (isVisible) => {
  const { userPlaylist, selectedPlaylist, fetchPlaylist } =
    useContext(PlaylistContext);
  let presentShareKey = "";

  if (selectedPlaylist && userPlaylist[selectedPlaylist.index]) {
    presentShareKey = userPlaylist[selectedPlaylist.index].share_key;
  }

  const [{ isLoading, response }, fetch] = useFetchAPI();
  const [shareKey, setShareKey] = useState(null);

  useEffect(() => {
    if (isVisible) {
      if (presentShareKey === null) {
        fetch({
          api: postUserPlaylistShareKeyAPI,
          payload: {
            subpath: { id: userPlaylist[selectedPlaylist.index].id },
            headers: {
              "Content-type": "application/json",
            },
          },
        });
      } else {
        setShareKey(presentShareKey);
      }
    }
  }, [isVisible, presentShareKey]);

  useEffect(() => {
    if (isLoading === false && response && response.status === "ok") {
      setShareKey(response.playlist.share_key);
      fetchPlaylist();
    }
  }, [isLoading]);

  return shareKey;
};

export default useCreatePlaylistShareKey;

import { authAction } from "../../actions";

const initialState = {
  authData: {},
  token: null,
};

const authAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case authAction.LOGIN_SUCCESS:
    case authAction.LOGIN_FAILURE:
    case authAction.LOGOUT_FAILURE:
      return { ...state, ...action.payload };

    case authAction.STORE_TOKEN:
      return { ...state, token: action.payload };

    case authAction.LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default authAppReducer;

import { useState } from "react";

import { postForgotPasswordAPI } from "../../api/auth.http.service";
import { useFetchAPI } from "../../hooks";
import { mho72, mho72_2x } from "../../assets/images";
import { Button, Link, Logo } from "../../commonUI";
import { ROUTE } from "../../helper/constants";
import {
  StyledLoginContainer,
  StyledLoginFormContainer,
  StyledLogoContainer,
  StyledFormItemContainer,
  StyledLabel,
  StyledInput,
  // StyledErrorLogo,
} from "./styles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [{ response }, fetchLogin] = useFetchAPI();

  const handleOnSendEmail = () => {
    fetchLogin({
      api: postForgotPasswordAPI,
      payload: {
        body: {
          email,
        },
      },
    });
  };

  return (
    <StyledLoginContainer>
      <StyledLoginFormContainer>
        <StyledLogoContainer>
          <Logo src={mho72} srcSet={mho72_2x} />
        </StyledLogoContainer>
        {response && response.status === "ok" ? (
          <div class="alert alert-success" role="alert">
            We&apos;ve sent instructions on how to change a password on your
            email
          </div>
        ) : (
          <>
            <p>Enter your email to request a new password</p>
            <StyledFormItemContainer>
              <StyledLabel>Email</StyledLabel>
              <StyledInput
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </StyledFormItemContainer>
            <Button type="button" onClick={handleOnSendEmail}>
              Send
            </Button>
          </>
        )}
        <div className="d-flex justify-content-center">
          Return to &nbsp;
          <Link href={`/${ROUTE.userLogin}`}>Sign in</Link>
        </div>
      </StyledLoginFormContainer>
    </StyledLoginContainer>
  );
};

export default Login;

import { useEffect, useRef } from 'react'

function useDidUpdateEffect(callback, inputs) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      callback()
    } else {
      didMountRef.current = true
    }
  }, inputs)
}

export default useDidUpdateEffect

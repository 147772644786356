import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTE } from "../helper/constants";
import NonAdminContainer from "./NonAdminContainer";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.app.auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <NonAdminContainer isRegisteredUser>
            <Component {...props} />
          </NonAdminContainer>
        ) : (
          <Redirect to={{ pathname: `/${ROUTE.userLogin}` }} />
        )
      }
    />
  );
};

export default PrivateRoute;

import { combineReducers } from "redux";

import appReducer from "./app";
// import uiReducer from "./ui";
// import errorReducer from "./error.reducer";
// import userReducer from "./domain/user.reducer";

const reducers = combineReducers({
  app: appReducer,
  // ui: uiReducer,
  // error: errorReducer,

  // users: userReducer,
});

export default reducers;

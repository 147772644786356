import styled from "styled-components";

const StyledLink = styled.a.attrs((props) => ({
  ...(props.onClick && { href: "javascript:void(0)" }),
}))`
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;

  color: #5cb85c;
  border: 0;
  background-color: transparent;
  padding: 0;

  :hover {
    color: #5cb85c;
    cursor: pointer;
  }
`;

const Link = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

export default Link;

import styled from "styled-components";

import { formatDate } from "../helper/utility";
import { useMediaQuery } from "../hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  ${({ isMobileWindow }) => isMobileWindow && "justify-content: center;"}

  img {
    max-width: 120px;
  }
  div {
    padding: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Album = ({ collectionName, releaseDate, thumbnail }) => {
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  return (
    <Container isMobileWindow={isMobileWindow}>
      <img className="img-responsive" src={thumbnail} alt={collectionName} />
      <div>
        <p>
          <span>{collectionName}</span>
          <br />
          <span>{formatDate(releaseDate, "eeee d, yyyy")}</span>
        </p>
      </div>
    </Container>
  );
};

export default Album;

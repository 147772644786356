import styled, { keyframes } from "styled-components";

const Icon = styled.button.attrs({
  type: "button",
})`
  font-size: 14px;
  width: 34px;
  height: 34px;
  flex-basis: 34px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  outline: none;

  background-color: ${({ bg }) => {
    switch (bg) {
      case "blue":
        return "#337AB7";
      case "red":
        return "#D9534F";
      default:
        return "transparent";
    }
  }};
  color: ${({ bg }) => (bg === "transparent" ? "#000" : "#fff")};
  border: ${({ bg }) => (bg === "transparent" ? "1px solid #ccc" : "0px")};
`;
const EditPlayListButton = styled.button`
  width: 100px;
  outline: none;
  padding: 5px;
  border: 0;
  color: #aaa;
  background: transparent;
  font-size: 12px;
  float: right;
`;
const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;

  @media (max-width: 576px) {
    padding: 10px 0px;
  }

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
const StyledFooter = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 376px) {
    flex-direction: column;
    text-align: left;
  }
`;

const moveLeft = keyframes`
  from {
    opacity: 0.5;
    left: 100px;
  }
  to {
    opacity: 1;
    left: 80px;
  }
`;
const OtherDeviceActionsContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 80px;
  border-radius: 0.25em;
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 50%);
  padding: 10px;
  animation: ${moveLeft} 0.5s ease forwards;

  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-left: 0;
    border-right: 10px solid white;
    top: calc(50% - 8px);
    left: -10px;
  }
`;

export {
  Icon,
  EditPlayListButton,
  StyledActionsContainer,
  StyledFooter,
  OtherDeviceActionsContainer,
};

import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTE } from "../helper/constants";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import AdminRoute from "./AdminRoute";
import FourZeroFour from "../components/FourZeroFour";
import Login from "../components/Auth/Login";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Projects from "../components/Projects";
import Playlist from "../components/Playlist";
import SharedPlaylist from "../components/Playlist/SharedPlaylist";
import Backend from "../components/Backend";
import NonAdminContainer from "./NonAdminContainer";

const SharedPlaylistComponent = (props) => (
  <NonAdminContainer isRegisteredUser={false}>
    <SharedPlaylist {...props} />
  </NonAdminContainer>
);

const Routes = () => {
  const token = useSelector((state) => state.app.auth.token);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect
              to={token ? `/${ROUTE.projects}` : `/${ROUTE.userLogin}`}
            />
          )}
        />
        <AuthRoute exact path={`/${ROUTE.userLogin}`} component={Login} />
        <AuthRoute
          exact
          path={`/${ROUTE.userForgot}`}
          component={ForgotPassword}
        />
        <PrivateRoute exact path={`/${ROUTE.projects}`} component={Projects} />
        <PrivateRoute exact path={`/${ROUTE.playlist}`} component={Playlist} />
        <Route
          path={`/${ROUTE.sharedPlaylist}`}
          component={SharedPlaylistComponent}
        />
        <AdminRoute
          exact={false}
          path={`/${ROUTE.backend.landing}`}
          component={Backend}
        />
        <Route exact path="/404" component={FourZeroFour} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

export const fetchData = async (api, payload) => {
  const response = await api(payload);
  return response;
};

export const manipulateFetchedData = ({ isMounted, result, dispatch }) => {
  if (isMounted) {
    if (result.isSuccess) {
      dispatch({ type: "FETCH_SUCCESS", payload: result });
    } else {
      const errorMessage =
        (result.errors ? Object.values(result.errors)[0] : result.message) ||
        "Something went wrong. Please try later";
      throw new Error(errorMessage);
    }
  }
};

export const handleFetch = async ({ api, payload }) => {
  if (payload.token) {
    const withFirstTokenResponse = await fetchData(api, payload);
    return withFirstTokenResponse;
  }
  return fetchData(api, payload);
};

import { useState, useContext } from "react";

import { useMediaQuery } from "../../../hooks";
import { ActionSheet } from "../../../commonUI";
import PlaylistContext from "../PlaylistContext";
import { PlaylistOptionsContainer, PlaylistIconContainer } from "../styles";
import Customize from "./Customize";
import SharePlaylist from "./SharePlaylist";
import PlaylistActions from "./PlaylistActions";
import PlaylistForm from "./PlaylistActions/PlaylistForm";

const items = [
  { key: "addPlaylist", heading: "New Playlist", icon: "fa fa-plus-circle" },
  // { key: "song", heading: "Add new song", icon: "fa fa-plus" },
  { key: "reorderSongs", heading: "Re-order songs", icon: "fa fa-bars" },
  { key: "customize", heading: "Design", icon: "fa fa-picture-o" },
  { key: "playlist", heading: "Saved Playlist", icon: "fa fa-list" },
];

const Options = () => {
  const [selectedOption, setSelectedOption] = useState("addPlaylist");
  const { settings } = useContext(PlaylistContext);
  const isMobileWindow = useMediaQuery("(max-width: 767px)");
  const { onAlterSongDragable } = useContext(PlaylistContext);

  const handleOnClose = () => setSelectedOption(undefined);

  return (
    <>
      {isMobileWindow ? (
        <ActionSheet>
          {items.map(({ key, heading, icon }) => {
            const onClickItem = () => {
              if (key === "reorderSongs") {
                onAlterSongDragable();
                return;
              }
              setSelectedOption(key);
            };
            return (
              <li key={key} onClick={onClickItem}>
                <i className={icon} />
                {heading ? <span>{heading}</span> : null}
              </li>
            );
          })}
        </ActionSheet>
      ) : (
        <PlaylistOptionsContainer>
          <PlaylistIconContainer
            settings={settings}
            onClick={() => setSelectedOption("share")}
            className="fa fa-link"
          />
          <PlaylistIconContainer
            settings={settings}
            onClick={() => setSelectedOption("customize")}
            className="fa fa-picture-o"
          />
          <PlaylistIconContainer
            settings={settings}
            onClick={() =>
              !selectedOption ? setSelectedOption("playlist") : handleOnClose()
            }
            className="fa fa-list"
          />
        </PlaylistOptionsContainer>
      )}
      <SharePlaylist
        isVisible={selectedOption === "share"}
        onClose={handleOnClose}
      />
      <Customize
        isVisible={selectedOption === "customize"}
        settings={settings}
        onClose={handleOnClose}
      />
      <PlaylistActions
        isVisible={selectedOption === "playlist"}
        onClose={handleOnClose}
        onShareLink={() => setSelectedOption("share")}
      />
      <PlaylistForm
        type="add"
        isVisible={selectedOption === "addPlaylist"}
        onClose={handleOnClose}
      />
    </>
  );
};

export default Options;

import { useState, useEffect } from "react";

import { Modal, ModalStyles } from "../../../commonUI";
import { isValidEmailAddress, isValidPassword } from "../../../helper/utility";
import { postUserCreateNewAPI } from "../../../api/admin.http.service";
import { useFetchAPI } from "../../../hooks";
import { Input } from "../../../styled";

const UserAdd = ({ isVisible, onCloseModal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [{ isLoading, response }, fetch] = useFetchAPI();

  useEffect(() => {
    if (isVisible) {
      setEmail("");
      setPassword("");
    }
  }, [isVisible]);

  useEffect(() => {
    if (isLoading === false && response.status === "ok") {
      onCloseModal(true);
    }
  }, [isLoading]);

  const onClickCreate = () => {
    fetch({
      api: postUserCreateNewAPI,
      payload: {
        body: {
          email,
          password,
          is_active: 1,
          name: "",
        },
        headers: {
          "Content-type": "application/json",
        },
      },
    });
  };

  return (
    <Modal isVisible={isVisible} backgroundColor="rgba(240, 243, 244, 0.8)">
      <Modal.Header
        icon="fa fa-envelope"
        title="Add user"
        onCloseModal={onCloseModal}
      />
      <Modal.Form>
        <Modal.FormGroup title="Email">
          <Input
            value={email}
            isInvalid={email && !isValidEmailAddress(email)}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Modal.FormGroup>
        <Modal.FormGroup title="Password">
          <Input
            value={password}
            isInvalid={password && !isValidPassword(password)}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Modal.FormGroup>
      </Modal.Form>
      <ModalStyles.SubmitContainer>
        <ModalStyles.SubmitButton
          disabled={!isValidEmailAddress(email)}
          kind="success"
          onClick={onClickCreate}
        >
          Save
        </ModalStyles.SubmitButton>
        <ModalStyles.SubmitButton kind="default" onClick={onCloseModal}>
          Cancel
        </ModalStyles.SubmitButton>
      </ModalStyles.SubmitContainer>
    </Modal>
  );
};

export default UserAdd;

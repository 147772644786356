import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import pick from "lodash/pick";

import { getUserPlaylistSharedAPI } from "../../api/user.http.service";
import { useFetchAPI } from "../../hooks";
import PlaylistContext from "./PlaylistContext";
import { PlaylistContainer, PlaylistContainerOverlay } from "./styles";
import Songs from "./Songs";

const SharedPlaylist = (props) => {
  const { sharedKey } = useParams();
  const [{ isLoading, response }, fetch] = useFetchAPI();
  const [settings, setSettings] = useState(undefined);
  const [selectedPlaylist, setSelectedPlaylist] = useState(undefined);
  const [isError, setIsError] = useState(undefined);

  useEffect(() => {
    fetch({
      api: getUserPlaylistSharedAPI,
      payload: {
        params: {},
        subpath: {
          id: sharedKey,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isLoading === false) {
      if (response && response.status === "ok") {
        setSettings(response.data.useroption);
        setSelectedPlaylist({
          data: pick(response.data, [
            "can_download",
            "id",
            "created_at",
            "name",
          ]),
        });
        setIsError(false);
      } else {
        setIsError(true);
      }
    }
  }, [isLoading]);

  console.log(response);

  if (isError === undefined) {
    return null;
  }

  if (isError) {
    return (
      <div className="container">
        <h2 className="text-center">
          Sorry! We couldn&apos;t find what you were looking for.
        </h2>
      </div>
    );
  }

  return (
    <PlaylistContext.Provider
      value={{
        settings,
        selectedPlaylist,
        isSongDragable: false,
      }}
    >
      <PlaylistContainer settings={settings}>
        <PlaylistContainerOverlay settings={settings} />
        <Songs settings={settings} isRegisteredUser={false} />
      </PlaylistContainer>
    </PlaylistContext.Provider>
  );
};

export default SharedPlaylist;

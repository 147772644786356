import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { ROUTE } from "../helper/constants";

const AdminRoute = ({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.app.auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `/${ROUTE.userLogin}` }} />
        )
      }
    />
  );
};

export default AdminRoute;

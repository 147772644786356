import styled from "styled-components";

const StyledProgress = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
  border-radius: 3px;
  margin: 4px 0px;
  background-color: ${({ isVisible }) => (isVisible ? "#eee" : "transparent")};

  &:hover {
    cursor: ${({ seekable }) => (seekable ? "pointer" : "default")};
  }

  div {
    width: ${({ progress }) => `${progress}%`};
    height: 3px;
    z-index: 1;
    background-color: #009de3;
  }

  &:before {
    content: "";
    display: ${({ isVisible }) => (isVisible ? "inline-block" : "none")};
    position: absolute;
    top: -4px;
    left: ${({ progress }) => `calc(${progress}% - 10px)`};
    margin-left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #009de3;
  }
`;

const ProgressBar = ({ barRef, isVisible, progress, seekable, onSeek }) => {
  return (
    <StyledProgress
      ref={barRef}
      isVisible={isVisible}
      progress={progress}
      seekable={seekable}
      onClick={(e) => seekable && onSeek(e)}
    >
      {isVisible ? <div /> : null}
    </StyledProgress>
  );
};

export default ProgressBar;
